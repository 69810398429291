import cache, { PlainLocalStorageDriver, setCacheConfigurations } from '@mongez/cache';
import { setEncryptionConfigurations } from '@mongez/encryption';
import { setLocalizationConfigurations } from '@mongez/localization';
import { AES } from 'crypto-js';
import { LOCALE_CODE_CACHE_KEY } from './constants';

// set encryption configurations
setEncryptionConfigurations({
  key: 'Taager-2025',
  driver: AES,
});

// the usage for the cache driver will be by the cache manager not the driver.
setCacheConfigurations({
  // the driver will be set based on the environment
  // if we are in production we will use the EncryptedLocalStorageDriver, which will encrypt the data in the local storage
  // if we are in development we will use the PlainLocalStorageDriver, which will store the data as it is in the local storage
  driver: new PlainLocalStorageDriver(),
  // this will prefix any key we set in the cache
  prefix: 'tgr--', // Taager
});

// to test translation, comment the following line and change the locale code to your desired one
// cache.set(LOCALE_CODE_CACHE_KEY, 'en')

setLocalizationConfigurations({
  // we will have 6 locale codes: ar, en, ar-eg, ar-ae, ar-sa, ar-iq
  // the default locale code will be 'ar'
  // also we will have a fallback locale code which will be 'ar'
  // so we can have common translations in the 'ar' locale and the specific translations in the other locales
  // this will reduce the number of translations thus the bundle size.
  defaultLocaleCode: cache.get(LOCALE_CODE_CACHE_KEY, 'ar'),
  fallback: 'ar',
});
