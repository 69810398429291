import { Injectable } from '@angular/core';
import { StoreModel } from 'src/app/core/domain/store.model';
import {
  WOOCOMMERCE_INTEGRATION_URL,
  YOUCAN_INTEGRATION_URL,
  ZID_INTEGRATION_URL,
} from 'src/app/presentation/shared/constants';

const STORES_DATA: StoreModel[] = [
  {
    storeName: 'STORES.YOUCAN.NAME',
    storeDescription: 'STORES.YOUCAN.DESCRIPTION',
    storeImage: 'https://storage.googleapis.com/taager-public-media/learning/you-can.svg',
    provider: 'youcan',
    redirectUri: YOUCAN_INTEGRATION_URL,
    howToLinkVideo: 'https://www.youtube.com/embed/lFC15nJs_-A?si=3DEubwXnzXrxzRwJ',
    supportedCountries: ['EGY', 'SAU'],
  },
  {
    storeName: 'STORES.ZID.NAME',
    storeDescription: 'STORES.ZID.DESCRIPTION',
    storeImage: 'https://storage.googleapis.com/taager-public-media/learning/zid.svg',
    provider: 'zid',
    redirectUri: ZID_INTEGRATION_URL,
    supportedCountries: ['SAU'],
  },
  {
    storeName: 'STORES.WOOCOMMERCE.NAME',
    storeDescription: 'STORES.WOOCOMMERCE.DESCRIPTION',
    storeImage: 'https://storage.googleapis.com/taager-public-media/learning/woo-commerce.svg',
    provider: 'WOOCOMMERCE',
    redirectUri: WOOCOMMERCE_INTEGRATION_URL,
    supportedCountries: ['EGY', 'SAU'],
  },
];

@Injectable({
  providedIn: 'root',
})
export class StoresLocalDataSource {
  getStores(): StoreModel[] {
    return STORES_DATA;
  }
}
