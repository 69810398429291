<div class="notification-container">
  <button
    class="notification-icon"
    [matMenuTriggerFor]="notificationMenu"
    #t="matMenuTrigger"
    [ngClass]="{ 'menu-opened': t.menuOpen }"
    (click)="presenter.emitViewEvent({ type: 'ClickNotifications' })"
  >
    <span class="caption2--bold" *ngIf="!t.menuOpen && viewState.totalNotificationCount > 0">{{
      viewState.totalNotificationCount
    }}</span>
    <ng-container *ngIf="!t.menuOpen; else menuOpenImg">
      <img src="assets/img/header-icons/notification-bell.svg" />
    </ng-container>
    <ng-template #menuOpenImg>
      <img src="assets/img/header-icons/notification-bell-white.svg" />
    </ng-template>
  </button>
  <mat-menu #notificationMenu="matMenu" xPosition="before" class="notifications-dropdown-menu">
    <div class="menu" [dir]="isLTR ? 'ltr' : 'rtl'">
      <div class="menu__header">
        <p class="menu__header_title caption1--bold">
          {{ trans('HEADER.NOTIFICATIONS_DROPDOWN.HEADER') }}
        </p>
      </div>

      <div *ngIf="viewState.notifications" class="menu__items">
        <ng-container *ngFor="let notification of viewState.notifications">
          <app-notifications-dropdown-item [notification]="notification" />
        </ng-container>
      </div>

      <div
        *ngIf="!viewState.notifications || viewState.notifications.length === 0"
        class="menu__empty"
      >
        <img src="assets/img/header-icons/empty-notification.svg" />
        <p class="caption1--bold menu__empty_title">
          {{ trans('HEADER.NOTIFICATIONS_DROPDOWN.EMPTY_TITLE') }}
        </p>
        <p class="caption2--regular menu__empty_subtitle">
          {{ trans('HEADER.NOTIFICATIONS_DROPDOWN.EMPTY_SUBTITLE') }}
        </p>
      </div>
    </div>
  </mat-menu>
</div>
