import cache from '@mongez/cache';
import { getCurrentLocaleCode, setCurrentLocaleCode } from '@mongez/localization';
import { LOCALE_CODE_CACHE_KEY } from '../constants';

export function updateLocaleCode(localeCode: string): void {
  cache.set(LOCALE_CODE_CACHE_KEY, localeCode);

  setCurrentLocaleCode(localeCode);
}

export function getLocalizedValue(value: any): string {
  if (getCurrentLocaleCode() === 'en') {
    return value?.en || value?.englishName || value || '';
  }

  return value?.ar || value?.arabicName || value || '';
}
