// @ts-ignore
import * as CountryResolver from '@taager-experience-shared/country-resolver';
import { getLocalizedValue } from '../../../drivers/data';

export function getCountryFlag(isoCode3: string): string {
  return CountryResolver.getCountryFromIsoCode3(isoCode3).flagUrl || '';
}

export function getCountryName(isoCode3: string): string {
  return getLocalizedValue(CountryResolver.getCountryFromIsoCode3(isoCode3));
}

export function getCurrencyName(isoCode3: string): string {
  return getLocalizedValue(CountryResolver.getCountryFromIsoCode3(isoCode3).currency);
}
