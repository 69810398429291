import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { NotificationItemModel } from 'app/core/domain/user-notification.model';
import { LocalizedComponent } from 'app/presentation/base/localized.component';
import {
  PRODUCT_LOCK_CREATED,
  PRODUCT_LOCK_QUANTITY_EXPIRING,
  PRODUCT_LOCK_REMOVED,
  PRODUCT_LOCK_TIME_EXPIRING,
  PRODUCT_LOCK_UPDATED,
} from 'app/presentation/shared/constants/user-notification';
import { NotificationsDropdownPresenter } from '../notifications-dropdown.presenter';

@Component({
  selector: 'app-notifications-dropdown-item',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './notifications-dropdown-item.component.html',
  styleUrls: ['./notifications-dropdown-item.component.scss'],
})
export class NotificationsDropdownItemComponent extends LocalizedComponent implements OnInit {
  public presenter: NotificationsDropdownPresenter = inject(NotificationsDropdownPresenter);

  @Input() notification: NotificationItemModel;

  public shouldShowTallyFormLink: boolean;

  public shouldShowViewProgress: boolean;

  ngOnInit(): void {
    this.setupTemplate();
  }

  public setupTemplate(): void {
    const showTallyFormLinkCases = new Set([
      PRODUCT_LOCK_QUANTITY_EXPIRING,
      PRODUCT_LOCK_TIME_EXPIRING,
      PRODUCT_LOCK_REMOVED,
    ]);

    const showViewProgressCases = new Set([
      PRODUCT_LOCK_QUANTITY_EXPIRING,
      PRODUCT_LOCK_TIME_EXPIRING,
      PRODUCT_LOCK_REMOVED,
      PRODUCT_LOCK_CREATED,
      PRODUCT_LOCK_UPDATED,
    ]);

    this.shouldShowTallyFormLink = showTallyFormLinkCases.has(this.notification.content.type);
    this.shouldShowViewProgress = showViewProgressCases.has(this.notification.content.type);
  }
}
