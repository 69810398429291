import { NotificationItemModel } from 'app/core/domain/user-notification.model';
import { UserNotificationRepository } from 'app/core/repositories/user-notification.repository';
import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';

export class GetUserNotificationsUseCase implements UseCase<void, NotificationItemModel[]> {
  constructor(private _userNotificationRepository: UserNotificationRepository) {}

  execute(): Observable<NotificationItemModel[]> {
    return this._userNotificationRepository.getUserNotifications();
  }
}
