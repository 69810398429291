import { Injectable } from '@angular/core';
import {
  NotificationItemModel,
  NotificationReadModel,
} from 'app/core/domain/user-notification.model';
import { UserNotificationRepository } from 'app/core/repositories/user-notification.repository';
import { map, Observable } from 'rxjs';
import { UserNotificationAPIservice } from './user-notification-apis.service';

@Injectable({
  providedIn: 'root',
})
export class UserNotificationRepositoryImplementation extends UserNotificationRepository {
  constructor(private _userNotificationAPIService: UserNotificationAPIservice) {
    super();
  }

  getUserNotifications(): Observable<NotificationItemModel[]> {
    return this._userNotificationAPIService
      .getUserNotifications()
      .pipe(map((response) => response.data.results));
  }

  readNotification(params: {
    notificationId: string;
    data: NotificationReadModel;
  }): Observable<void> {
    return this._userNotificationAPIService.readNotification(params);
  }

  readUserNotifications(params: NotificationReadModel): Observable<void> {
    return this._userNotificationAPIService.readUserNotifications(params);
  }
}
