/* eslint-disable guard-for-in */
import { BaseRepository } from '@features/shared';
import { ProductLockRequestRepositoryContract } from '../../domain';
import { ProductLockRequestListData } from '../../domain/types/data.types';
import { lockRequestAPI } from '../constants';
import { ProductLockRequestCreateModel, ProductLockRequestModel } from '../models';

export class ProductLockRequestRepository
  extends BaseRepository
  implements ProductLockRequestRepositoryContract
{
  public async productLockRequestsList(): Promise<ProductLockRequestModel[]> {
    const params = {
      page: 1,
      pageSize: 999,
    };
    const response = await this.http.get<ProductLockRequestListData>(
      lockRequestAPI.PRODUCT_REQUESTS,
      {
        params,
      },
    );
    return response.data.results;
  }

  public async productLockRequestCreate(data: ProductLockRequestCreateModel): Promise<any> {
    const response = await this.http.post(lockRequestAPI.PRODUCT_REQUESTS, data);
    return response;
  }
}
