import { Observable } from 'rxjs';
import { NotificationItemModel, NotificationReadModel } from '../domain/user-notification.model';

export abstract class UserNotificationRepository {
  abstract getUserNotifications(): Observable<NotificationItemModel[]>;
  abstract readUserNotifications(params: NotificationReadModel): Observable<void>;
  abstract readNotification(params: {
    notificationId: string;
    data: NotificationReadModel;
  }): Observable<void>;
}
