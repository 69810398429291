import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationReadModel } from 'app/core/domain/user-notification.model';
import { API_URLS } from 'app/presentation/shared/constants';
import { Observable } from 'rxjs/internal/Observable';
import { NotificationListEntity } from './entities/user-notification.entity';

@Injectable({
  providedIn: 'root',
})
export class UserNotificationAPIservice {
  constructor(private http: HttpClient) {}

  getUserNotifications(): Observable<NotificationListEntity> {
    return this.http.get<NotificationListEntity>(API_URLS.USER_NOTIFICATIONS);
  }

  readUserNotifications(params: NotificationReadModel): Observable<void> {
    return this.http.patch<void>(API_URLS.USER_NOTIFICATIONS, params);
  }

  readNotification(params: {
    notificationId: string;
    data: NotificationReadModel;
  }): Observable<void> {
    return this.http.patch<void>(
      API_URLS.USER_NOTIFICATIONS_READ(params.notificationId),
      params.data,
    );
  }
}
