import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from 'angularx-social-login';
import { UserNotificationRepository } from 'app/core/repositories/user-notification.repository';
import { UtilitiesRepository } from 'app/core/repositories/utilities.repository';
import { GetUserNotificationsUseCase } from 'app/core/usecases/user-notification/get-user-notifications.usecase';
import { SetNotificationUseCase } from 'app/core/usecases/user-notification/set-notification.usecase';
import { SetUserNotificationsUseCase } from 'app/core/usecases/user-notification/set-user-notifications.usecase';
import { UploadImageUseCase } from 'app/core/usecases/utilities/upload-image.usecase';
import { environment } from 'src/environments/environment';
import { AnalyticsRepository } from '../core/repositories/analytics.repository';
import { BulkPreOrderRepository } from '../core/repositories/bulk-pre-order.repository';
import { CartRepository } from '../core/repositories/cart.repository';
import { CommercialCategoryRepository } from '../core/repositories/commercial-categories.repository';
import { CountriesRepository } from '../core/repositories/countries.repository';
import { DukanShopRepository } from '../core/repositories/dukan-shop.repository';
import { DynamicIncentiveProgramRepository } from '../core/repositories/dynamic-incentive-program.repository';
import { IncentiveProgramRepository } from '../core/repositories/incentive-program.repository';
import { LeadsRepository } from '../core/repositories/lead.repository';
import { LearningCenterRepository } from '../core/repositories/learning-center.repository';
import { LearningRepository } from '../core/repositories/learning.repository';
import { LoyaltyProgramRepository } from '../core/repositories/loyalty-program.repository';
import { MarketAvailabilityRepository } from '../core/repositories/market-availability.repository';
import { MerchantInsightsRepository } from '../core/repositories/merchant-insights.repository';
import { MerchantStoreRepository } from '../core/repositories/merchant-store.repository';
import { NewIncentiveProgramRepository } from '../core/repositories/new-incentive-program.repository';
import { OrderRepository } from '../core/repositories/order.repository';
import { PricingCalculatorRepository } from '../core/repositories/pricing-calculator-repository';
import { ProductsRepository } from '../core/repositories/products.repository';
import { ProvinceRepository } from '../core/repositories/province.repository';
import { QuestionnaireBannerRepository } from '../core/repositories/questionnaire-banner.repository';
import { QuestionnaireRepository } from '../core/repositories/questionnaire.repository';
import { ReferralProgramRepository } from '../core/repositories/referral-program.repository';
import { RemoteConfigRepository } from '../core/repositories/remote-config.repository';
import { StockAvailabilityRepository } from '../core/repositories/stock-availability.repository';
import { UserLocationRepository } from '../core/repositories/user-location.repository';
import { UserRepository } from '../core/repositories/user.repository';
import { WalletRepository } from '../core/repositories/wallet.repository';
import { IdentifyMixpanelInstanceUseCase } from '../core/usecases/analytics/identify-mixpanel-instance.usecase';
import { InitializeMixpanelUseCase } from '../core/usecases/analytics/initialize-mixpanel.usecase';
import { LogGTagEventUseCase } from '../core/usecases/analytics/log-gtag-event.usecase';
import { LogMixpanelEventUseCase } from '../core/usecases/analytics/log-mixpanel-event.usecase';
import { ResetMixpanelInstanceUsecase } from '../core/usecases/analytics/reset-mixpanel.instance.usecase';
import { SetUserAcquisitionChannelUseCase } from '../core/usecases/auth/set-user-acquisition.usecase';
import { SetUserReferralUseCase } from '../core/usecases/auth/set-user-referral.usecase';
import { AcceptPreOrderDownPaymentUseCase } from '../core/usecases/bulk-preorder/accept-pre-order-down-payment.usecase';
import { CreateBulkPreOrderRequestUseCase } from '../core/usecases/bulk-preorder/create-bulk-pre-order-request.usecase';
import { GetBulkPreOrderRequestsUseCase } from '../core/usecases/bulk-preorder/get-bulk-pre-order-requests.usecase';
import { GetPreOrderBenefitsUseCase } from '../core/usecases/bulk-preorder/get-pre-order-benefits.usecase';
import { GetPreOrderCommercialCategoriesUseCase } from '../core/usecases/bulk-preorder/get-pre-order-commercial-categories.usecase';
import { GetPreOrderRequestsSummaryUseCase } from '../core/usecases/bulk-preorder/get-pre-order-requests-summary.usecase';
import { GetPreorderableProductsUseCase } from '../core/usecases/bulk-preorder/get-preorderable-products-use.case';
import { RejectPreOrderDownPaymentUseCase } from '../core/usecases/bulk-preorder/reject-pre-order-down-payment.usecase';
import { AddToCartUseCase } from '../core/usecases/cart/add-to-cart.usecase';
import { EmptyCartUseCase } from '../core/usecases/cart/empty-cart.usecase';
import { GetUserCartUseCase } from '../core/usecases/cart/get-cart.usecase';
import { RemoveProductFromCartUseCase } from '../core/usecases/cart/remove-product-from-cart.usecase';
import { UpdateCartUseCase } from '../core/usecases/cart/update-cart.usecase';
import { UpdateProductInCartUseCase } from '../core/usecases/cart/update-product-in-cart.usecase';
import { AddDukanProductMediaUseCase } from '../core/usecases/dukan-shop/add-dukan-product-media.usecase';
import { CreateDukanShopUseCase } from '../core/usecases/dukan-shop/create-dukan.usecase';
import { DeactivateDukanShopUseCase } from '../core/usecases/dukan-shop/deactivate-dukan.usecase';
import { DeleteDukanProductMediaUseCase } from '../core/usecases/dukan-shop/delete-dukan-product-media.usecase';
import { DeleteDukanProductUseCase } from '../core/usecases/dukan-shop/delete-dukan-product.usecase';
import { GetDukanProductDetailsUseCase } from '../core/usecases/dukan-shop/get-dukan-product-details.usecase';
import { GetDukanProductsUseCase } from '../core/usecases/dukan-shop/get-dukan-products.usecase';
import { GetDukanShopUseCase } from '../core/usecases/dukan-shop/get-dukan.usecase';
import { PushDukanProductUseCase } from '../core/usecases/dukan-shop/push-dukan-product.usecase';
import { ReactivateDukanShopUseCase } from '../core/usecases/dukan-shop/reactivate-dukan.usecase';
import { SetDukanProductsUseCase } from '../core/usecases/dukan-shop/set-dukan-products.usecase';
import { UpdateDukanAdsIntegrationUseCase } from '../core/usecases/dukan-shop/update-dukan-ads-integration.usecase';
import { UpdateDukanInfoUseCase } from '../core/usecases/dukan-shop/update-dukan-info.usecase';
import { UpdateDukanProductDetailsUseCase } from '../core/usecases/dukan-shop/update-dukan-product-details.usecase';
import { AcceptDynamicIncentiveProgramUseCase } from '../core/usecases/dynamic-incentive-program/accept-dynamic-incentive-program.usecase';
import { DeclineDynamicIncentiveProgramUseCase } from '../core/usecases/dynamic-incentive-program/decline-dynamic-incentive-program.usecase';
import { FeedbackDynamicIncentiveProgramUseCase } from '../core/usecases/dynamic-incentive-program/feedback-dynamic-incentive-program.usecase';
import { GetDynamicIncentiveProgramHistoryUseCase } from '../core/usecases/dynamic-incentive-program/get-dynamic-incentive-program-history.usecase';
import { GetDynamicIncentiveProgramUseCase } from '../core/usecases/dynamic-incentive-program/get-dynamic-incentive-program.usecase';
import { GetBottomLevelCategoriesUseCase } from '../core/usecases/get-bottom-level-categories.usecase';
import { GetCategoryHierarchyUseCase } from '../core/usecases/get-category-hierarchy-usecase';
import { GetCommercialCategoriesUseCase } from '../core/usecases/get-commercial-categories-usecase';
import { GetCommercialCategoryDataByIdUseCase } from '../core/usecases/get-commercial-category-data-by-id-usecase';
import { GetCountriesUseCase } from '../core/usecases/get-countries.usecase';
import { GetCountryVATByIsoCode3UseCase } from '../core/usecases/get-country-vat.usecase';
import { GetFeatureAttributeUsecase } from '../core/usecases/get-feature-attribute.usecase';
import { GetFeatureFlagUsecase } from '../core/usecases/get-feature-flag.usecase';
import { GetIncentiveProgramDetailsUseCase } from '../core/usecases/get-incentive-program-details.usecase';
import { GetNewIncentiveProgramDetailsUseCase } from '../core/usecases/get-new-incentive-program-details.usecase';
import { GetOperationRateUseCase } from '../core/usecases/get-operation-rate.usecase';
import { GetQuestionnaireEligibilityUseCase } from '../core/usecases/get-questionnaire-eligibility.usecase';
import { GetQuestionnaireQuestionsUseCase } from '../core/usecases/get-questionnaire-questions.usecase';
import { GetRemoteConfigUsecase } from '../core/usecases/get-remote-config.usecase';
import { GetLeadsListUseCase } from '../core/usecases/leads/get-leads-list.usecase';
import { GetContentBySubTopicIdUseCase } from '../core/usecases/learning-center/get-content-by-topic-id.usecase';
import { GetItemByContentIdUseCase } from '../core/usecases/learning-center/get-item-by-content-id.usecase';
import { GetSubTopicsUseCase } from '../core/usecases/learning-center/get-sub-topics.usecase';
import { GetTopicFromSubTopicId } from '../core/usecases/learning-center/get-topic-from-sub-topic-id.usecase';
import { GetTopicsUseCase } from '../core/usecases/learning-center/get-topics.usecase';
import { GetLoyaltyFAQsDataUseCase } from '../core/usecases/loyalty-program/get-faqs-data.usecase';
import { GetLoyaltyProgressUseCase } from '../core/usecases/loyalty-program/get-progress.usecase';
import { GetLoyaltyTiersDataUseCase } from '../core/usecases/loyalty-program/get-tiers-data.usecase';
import { GetMarketClosingReasonUseCase } from '../core/usecases/market-availability/get-closing-reason.usecase';
import { IsFeatureEnabledUseCase } from '../core/usecases/market-availability/is-feature-enabled.usecase';
import { GetInsightsDataUseCase } from '../core/usecases/merchant-insights/get-insights-data.usecase';
import { GetInsightsUrlUseCase } from '../core/usecases/merchant-insights/get-insights-url.usecase';
import { AddProductToStoreUseCase } from '../core/usecases/merchant-store/add-product-to-store.usecase';
import { GenerateAPIKeyUseCase } from '../core/usecases/merchant-store/generate-api-key-usecase';
import { GetAPIKeysUseCase } from '../core/usecases/merchant-store/get-api-keys-usecase';
import { GetStoresUseCase } from '../core/usecases/merchant-store/get-stores-usecase';
import { GetUserStoresUseCase } from '../core/usecases/merchant-store/get-user-stores-usecase';
import { LinkMerchantStoreUseCase } from '../core/usecases/merchant-store/link-merchant-store.usecase';
import { UnlinkMerchantStoreUseCase } from '../core/usecases/merchant-store/unlink-merchant-store.usecase';
import { CalculateOrderCostUseCase } from '../core/usecases/order/calculate-order-cost.usecase';
import { CancelOrderUseCase } from '../core/usecases/order/cancel-order.usecase';
import { GetChildOrdersUseCase } from '../core/usecases/order/get-child-orders.usecase';
import { GetFailedOrdersUseCase } from '../core/usecases/order/get-failed-orders.usecase';
import { GetOrderProductPricesUseCase } from '../core/usecases/order/get-order-product-prices.usecase';
import { GetOrderStatusListUseCase } from '../core/usecases/order/get-order-status-list.usecase';
import { MakeOrderByCartUseCase } from '../core/usecases/order/make-order-by-cart';
import { GetOrderActivityWithStatusUseCase } from '../core/usecases/order/order-activity-with-status.usecase';
import { GetOrdersListUseCase } from '../core/usecases/order/orders-list.usecase';
import { RateOrderUseCase } from '../core/usecases/order/rate-order.usecase';
import { TrackOrderActivityUseCase } from '../core/usecases/order/track-order-activity.usecase';
import { DeclineQuestionnaireUseCase } from '../core/usecases/post-decline-questionnaire';
import { CPACalculatorUseCase } from '../core/usecases/pricing-calculator/cpa-calculator.usecase';
import { CheckUserNewExperienceEligibilityUseCase } from '../core/usecases/products/check-user-new-experience-eligibility.usecase';
import { CreateProductCheckoutUseCase } from '../core/usecases/products/create-product-checkout.usecase';
import { GetProductCreativesEligibilityUseCase } from '../core/usecases/products/get-creatives.usecase';
import { GetProductCheckoutDetailUseCase } from '../core/usecases/products/get-product-checkout-detail.usecase';
import { GetProductVATUseCase } from '../core/usecases/products/get-product-vat.usecase';
import { GetUpsellableProductsUseCase } from '../core/usecases/products/get-upsellable-products.usecase';
import { GetVariantsByProductIdsUseCase } from '../core/usecases/products/get-variants-by-product-ids.usecase';
import { ListenToProductsPageExperienceChangesUseCase } from '../core/usecases/products/listen-to-products-page-experience-change.usecase';
import { SetSelectedProductsPageExperienceUseCase } from '../core/usecases/products/set-selected-products-page-experience.usecase';
import { ShouldDisplayNewHomePageUseCase } from '../core/usecases/products/should-display-new-home-page.usecase';
import { SubmitProductReviewUseCase } from '../core/usecases/products/submit-product-review.usecase';
import { UpdateProductCheckoutUseCase } from '../core/usecases/products/update-product-checkout.usecase';
import { GetProvinceListUseCase } from '../core/usecases/province/get-province-list.usecase';
import { GetProvinceZoneDistrictsUseCase } from '../core/usecases/province/get-province-zone-district-list.usecase';
import { GetProvinceZonesUseCase } from '../core/usecases/province/get-province-zone-list.usecase';
import { GetReferralStepsDataUseCase } from '../core/usecases/referral-program/get-referral-steps-data.usecase';
import { GetReferralsProgramUseCase } from '../core/usecases/referral-program/get-referrals-program.usecase';
import { GetStockAvailabilityUseCase } from '../core/usecases/stock-availability/get-stock-availability.usecase';
import { SendNotifyMeRequestsUseCase } from '../core/usecases/stock-availability/send-notify-me-request.usecase';
import { SubmitQuestionnaireAnswersUseCase } from '../core/usecases/submit-questionnaire-answers.usecase';
import { GetUserCountryUseCase } from '../core/usecases/user-location/get-user-country.usecase';
import { GetUserIPAddressUseCase } from '../core/usecases/user-location/get-user-ip-address.usecase';
import { CheckUserFeatureExistsUseCase } from '../core/usecases/user/check-user-feature-exists.usecase';
import { DeleteAccountUseCase } from '../core/usecases/user/delete-account.usecase';
import { GetOrderSummaryUseCase } from '../core/usecases/user/get-order-summary.usecase';
import { GetPhoneDataUseCase } from '../core/usecases/user/get-phone-data.usecase';
import { GetReferralsUseCase } from '../core/usecases/user/get-referrals.usecase';
import { GetUserProfileUseCase } from '../core/usecases/user/get-user-profile.usecase';
import { GetUserSuperPropertiesUseCase } from '../core/usecases/user/get-user-super-properties.usecase';
import { GetUserUseCase } from '../core/usecases/user/get-user2.usecase';
import { SetUserUseCase } from '../core/usecases/user/set-user-usecase';
import { UpdateProfilePictureUseCase } from '../core/usecases/user/update-profile-picture-usecase';
import { UpdateProfileUseCase } from '../core/usecases/user/update-profile-usecase';
import { UpdateSwitchedSelectedMarketUseCase } from '../core/usecases/user/update-switched-selected-market.usecase';
import { ChangeWalletPasswordUseCase } from '../core/usecases/wallet/change-wallet-password.usecase';
import { CreateSecureIntlBankTransferUseCase } from '../core/usecases/wallet/create-secure-intl-bank-transfer.usecase';
import { CreateSecurePayoneerUseCase } from '../core/usecases/wallet/create-secure-payoneer.usecase';
import { CreateSecureWithdrawalRequestUseCase } from '../core/usecases/wallet/create-secure-withdrawal-request-use.case';
import { CreateWithdrawalRequestUseCase } from '../core/usecases/wallet/create-withdrawal-request.usecase';
import { ForgotWalletPasswordUseCase } from '../core/usecases/wallet/forgot-wallet-password.usecase';
import { GetTransactionsSheetUseCase } from '../core/usecases/wallet/get-transactions-sheet.usecase';
import { GetWalletTransactionHistoryUseCase } from '../core/usecases/wallet/get-wallet-transaction.usecase';
import { GetWalletsUseCase } from '../core/usecases/wallet/get-wallets.usecase';
import { GetWithdrawalsListUseCase } from '../core/usecases/wallet/get-withdrawals-list.usecase';
import { RequestWithdrawalOtpUseCase } from '../core/usecases/wallet/request-withdrawal-otp.usecase';
import { ResetWalletPasswordUseCase } from '../core/usecases/wallet/reset-wallet-password.usecase';
import { WalletLoginUseCase } from '../core/usecases/wallet/wallet-login.usecase';
import { WithdrawIntlBankTransferUseCase } from '../core/usecases/wallet/withdraw-intl-bank-transfer.usecase';
import { WithdrawPayoneerUseCase } from '../core/usecases/wallet/withdraw-payoneer.usecase';
import { APP_URLS_CONSTANTS } from '../presentation/shared/constants';
import { AnalyticsRepositoryInjectionToken } from './injection-tokens/analytics-repository.injection-token';
import { appUrlsConstantsInjectionToken } from './injection-tokens/app-urls-constants.injection-token';
import { authInjectionToken } from './injection-tokens/auth-repository.injection-token';
import { cartRepositoryInjectionToken } from './injection-tokens/cart-repository.injection-token';
import { commercialCategoriesRepositoryToken } from './injection-tokens/commercial-categories-repository.injection-token';
import { dukanShopRepositoryToken } from './injection-tokens/dukan-shop-repository.injection-token';
import { DynamicIncentiveProgramRepositoryToken } from './injection-tokens/dynamic-incentive-program.injection-token';
import { leadsRepositoryInjectionToken } from './injection-tokens/leads-repository.injection-token';
import { learningCenterRepositoryToken } from './injection-tokens/learning-center-repository.injection-token';
import { LoyaltyProgramRepositoryToken } from './injection-tokens/loyalty-program.injection-token';
import { marketAvailabilityRepositoryToken } from './injection-tokens/market-availability-repository.injection-token';
import { merchantInsightsRepositoryToken } from './injection-tokens/merchant-insights-repository.injection-token';
import { MerchantStoreRepositoryInjectionToken } from './injection-tokens/merchant-store-repository.injection-token';
import { orderRepositoryInjectionToken } from './injection-tokens/order-repository.injection-token';
import { pricingCalculatorRepositoryToken } from './injection-tokens/pricing-calculator-repository.injection-token';
import { ProvinceRepositoryToken } from './injection-tokens/province-repository.inection-token';
import { referralProgramRepositoryToken } from './injection-tokens/referral-program-repository.injection-token';
import { stockAvailabilityRepositoryToken } from './injection-tokens/stock-availability-repository.injection-token';
import { userInjectionToken } from './injection-tokens/user.injection-token';
import { walletRepositoryInjectionToken } from './injection-tokens/wallet-repository.injection-token';
import { AnalyticsRepositoryImplementation } from './repositories/analytics/analytics-repository.impl';
import { UserLocationRepositoryImplementation } from './repositories/analytics/user-location-repository.impl';
import { AuthRepositoryImplementation } from './repositories/auth/auth-impl.repository';
import { BannerQuestionnaireRepositoryImplementation } from './repositories/banner-questionnaire/banner-questionnaire-impl.repository';
import { BulkPreOrderImplementationRepository } from './repositories/bulk-pre-order/bulk-pre-order-implementation.repository';
import { CartRepositoryImplementation } from './repositories/cart/cart-impl.repository';
import { CommercialCategoryImplementationRepository } from './repositories/commercial-categories/commercial-category-implementation.repository';
import { CountryImplementationRepository } from './repositories/countries/countries-implementation.repository';
import { DukanShopRepositoryImplementation } from './repositories/dukan-shop/dukan-shop-impl.repository';
import { DynamicIncentiveProgramRepositoryImplementation } from './repositories/dynamic-incentive-program/dynamic-incentive-program-impl.repository';
import { IncentiveProgramRepositoryImpl } from './repositories/incentive-program/incentive-program-impl.repository';
import { LeadsRepositoryImplementation } from './repositories/leads/leads-impl.repository';
import { LearningCenterRepositoryImpl } from './repositories/learning-center/learning-center-impl-repository';
import { LearningRepositoryImpl } from './repositories/learning/learning-impl.repository';
import { TierRepositoryImplementation } from './repositories/loyalty-program/loyalty-program-impl.repository';
import { MarketAvailabilityRepositoryImplementation } from './repositories/market-availability/market-availability.repository-implementation';
import { MerchantInsightsRepositoryImplementation } from './repositories/merchant-insights/merchant-insights-impl.repository';
import { MerchantStoreRepositoryImplementation } from './repositories/merchant-store/merchant-store-repository.impl';
import { NewIncentiveProgramRepositoryImpl } from './repositories/new-incentive-program/new-incentive-program-impl.repository';
import { OrderRepositoryImpl } from './repositories/order/order-impl.repository';
import { PricingCalculatorRepositoryImplementation } from './repositories/pricing-calculator/pricing-calculator-repository.impl';
import { ProductsImplementationRepository } from './repositories/products/products-implementation.repository';
import { ProvinceRepositoryImplementation } from './repositories/province/province-implementation.repository';
import { QuestionnaireRepositoryImplementation } from './repositories/questionnaire/questionnaire-implementation.repository';
import { ReferralProgramRepositoryImplementation } from './repositories/referral-program/referral-program-impl.repository';
import { RemoteConfigRepositoryImpl } from './repositories/remote-config/remote-config-impl.repository';
import { StockAvailabilityRepositoryImplementation } from './repositories/stock-availability/stock-availability-impl.repository';
import { UserNotificationRepositoryImplementation } from './repositories/user-notifications/user-notification-implementation.repository';
import { UserImplRepository } from './repositories/user/user-impl.repository';
import { UtilitiesRepositoryImplementation } from './repositories/utilities/utilities-implementation.repository';
import { WalletRepositoryImplementation } from './repositories/wallet/wallet-implementation.repository';
import {
  changePasswordUseCaseProvider,
  completeUserProfileUseCaseProvider,
  forgotPasswordUseCaseProvider,
  getRegisterTokenUseCaseProvider,
  loginAuthUserUseCaseProvider,
  loginUserWithGoogleUseCaseProvider,
  logoutUseCaseProvider,
  registerUseCaseProvider,
  requestEmailOTPUseCaseProvider,
  requestOTPUseCaseProvider,
  resetPasswordUseCaseProvider,
  setRegisterTokenUseCaseProvider,
  verifyEmailOTPUseCaseProvider,
  verifyPhoneNumberUseCaseProvider,
} from './usecase-providers/auth.provider';

const getIncentiveProgramDetailsUseCaseFactory = (
  incentiveProgramRepo: IncentiveProgramRepository,
): GetIncentiveProgramDetailsUseCase => new GetIncentiveProgramDetailsUseCase(incentiveProgramRepo);
export const getIncentiveProgramDetailsUseCaseProvider = {
  provide: GetIncentiveProgramDetailsUseCase,
  useFactory: getIncentiveProgramDetailsUseCaseFactory,
  deps: [IncentiveProgramRepository],
};

const getNewIncentiveProgramDetailsUseCaseFactory = (
  newIncentiveProgramRepo: NewIncentiveProgramRepository,
): GetNewIncentiveProgramDetailsUseCase =>
  new GetNewIncentiveProgramDetailsUseCase(newIncentiveProgramRepo);
export const getNewIncentiveProgramDetailsUseCaseProvider = {
  provide: GetNewIncentiveProgramDetailsUseCase,
  useFactory: getNewIncentiveProgramDetailsUseCaseFactory,
  deps: [NewIncentiveProgramRepository],
};

const getFeatureFlagUseCaseFactory = (
  remoteConfigRepo: RemoteConfigRepository,
): GetFeatureFlagUsecase => new GetFeatureFlagUsecase(remoteConfigRepo);
export const getFeatureFlagUseCaseProvider = {
  provide: GetFeatureFlagUsecase,
  useFactory: getFeatureFlagUseCaseFactory,
  deps: [RemoteConfigRepository],
};

const getFeatureAttributeUseCaseFactory = (
  remoteConfigRepo: RemoteConfigRepository,
): GetFeatureAttributeUsecase => new GetFeatureAttributeUsecase(remoteConfigRepo);
export const getFeatureAttributeUseCaseProvider = {
  provide: GetFeatureAttributeUsecase,
  useFactory: getFeatureAttributeUseCaseFactory,
  deps: [RemoteConfigRepository],
};

const getReferralsUseCaseFactory = (userRepository: UserRepository): GetReferralsUseCase =>
  new GetReferralsUseCase(userRepository);

export const getReferralsUseCaseProvider = {
  provide: GetReferralsUseCase,
  useFactory: getReferralsUseCaseFactory,
  deps: [userInjectionToken],
};

const deleteAccountUseCaseFactory = (userRepository: UserRepository): DeleteAccountUseCase =>
  new DeleteAccountUseCase(userRepository);

export const deleteAccountUseCaseProvider = {
  provide: DeleteAccountUseCase,
  useFactory: deleteAccountUseCaseFactory,
  deps: [userInjectionToken],
};

export const updateProfilePictureUseCaseFactory = (
  userRepository: UserRepository,
): UpdateProfilePictureUseCase => new UpdateProfilePictureUseCase(userRepository);
const updateProfilePictureUseCaseProvider = {
  provide: UpdateProfilePictureUseCase,
  useFactory: updateProfilePictureUseCaseFactory,
  deps: [userInjectionToken],
};

export const setUserUseCaseFactory = (userRepository: UserRepository): SetUserUseCase =>
  new SetUserUseCase(userRepository);
const setUserUseCaseProvider = {
  provide: SetUserUseCase,
  useFactory: setUserUseCaseFactory,
  deps: [userInjectionToken],
};

const getUserUseCaseFactory2 = (userRepository: UserRepository): GetUserUseCase =>
  new GetUserUseCase(userRepository);

export const getUserUseCaseProvider2 = {
  provide: GetUserUseCase,
  useFactory: getUserUseCaseFactory2,
  deps: [userInjectionToken],
};

const getUserSuperPropertiesUseCaseFactory = (
  userRepository: UserRepository,
): GetUserSuperPropertiesUseCase => new GetUserSuperPropertiesUseCase(userRepository);

export const getUserSuperPropertiesUseCaseProvider = {
  provide: GetUserSuperPropertiesUseCase,
  useFactory: getUserSuperPropertiesUseCaseFactory,
  deps: [userInjectionToken],
};

export const updateProfileUseCaseFactory = (userRepository: UserRepository): UpdateProfileUseCase =>
  new UpdateProfileUseCase(userRepository);
const updateProfileUseCaseProvider = {
  provide: UpdateProfileUseCase,
  useFactory: updateProfileUseCaseFactory,
  deps: [userInjectionToken],
};

export const checkUserFeatureExistsUseCaseFactory = (
  userRepository: UserRepository,
): CheckUserFeatureExistsUseCase => new CheckUserFeatureExistsUseCase(userRepository);
const checkUserFeatureExistsUseCaseProvider = {
  provide: CheckUserFeatureExistsUseCase,
  useFactory: checkUserFeatureExistsUseCaseFactory,
  deps: [userInjectionToken],
};

const getCommercialCategoriesUseCaseFactory = (
  commercialCategoryRepository: CommercialCategoryRepository,
): GetCommercialCategoriesUseCase =>
  new GetCommercialCategoriesUseCase(commercialCategoryRepository);

/* eslint-disable-next-line @typescript-eslint/naming-convention */
export const GetCommercialCategoriesUseCaseProvider = {
  provide: GetCommercialCategoriesUseCase,
  useFactory: getCommercialCategoriesUseCaseFactory,
  deps: [commercialCategoriesRepositoryToken],
};

const getCategoryHierarchyUseCaseFactory = (
  commercialCategoryRepository: CommercialCategoryRepository,
): GetCategoryHierarchyUseCase => new GetCategoryHierarchyUseCase(commercialCategoryRepository);
export const GetCategoryHierarchyUseCaseProvider = {
  provide: GetCategoryHierarchyUseCase,
  useFactory: getCategoryHierarchyUseCaseFactory,
  deps: [commercialCategoriesRepositoryToken],
};

const getBottomLevelCategoriesUseCaseFactory = (
  commercialCategoryRepository: CommercialCategoryRepository,
): GetBottomLevelCategoriesUseCase =>
  new GetBottomLevelCategoriesUseCase(commercialCategoryRepository);
export const GetBottomLevelCategoriesUseCaseProvider = {
  provide: GetBottomLevelCategoriesUseCase,
  useFactory: getBottomLevelCategoriesUseCaseFactory,
  deps: [commercialCategoriesRepositoryToken],
};

const getCommercialCategoryDataByIdUseCaseFactory = (
  commercialCategoryRepository: CommercialCategoryRepository,
): GetCommercialCategoryDataByIdUseCase =>
  new GetCommercialCategoryDataByIdUseCase(commercialCategoryRepository);
export const GetCommercialCategoryDataByIdUseCaseProvider = {
  provide: GetCommercialCategoryDataByIdUseCase,
  useFactory: getCommercialCategoryDataByIdUseCaseFactory,
  deps: [commercialCategoriesRepositoryToken],
};

/* Banner Questionnaire */
const getQuestionnaireEligibilityUseCaseFactory = (
  bannerRepository: QuestionnaireBannerRepository,
): GetQuestionnaireEligibilityUseCase => new GetQuestionnaireEligibilityUseCase(bannerRepository);
export const getQuestionnaireEligibilityUseCaseProvider = {
  provide: GetQuestionnaireEligibilityUseCase,
  useFactory: getQuestionnaireEligibilityUseCaseFactory,
  deps: [QuestionnaireBannerRepository],
};

const BannerQuestionnaireDeclineUseCaseFactory = (
  bannerRepository: QuestionnaireBannerRepository,
): DeclineQuestionnaireUseCase => new DeclineQuestionnaireUseCase(bannerRepository);
export const postDeclineQuestionnaireUseCaseProvider = {
  provide: DeclineQuestionnaireUseCase,
  useFactory: BannerQuestionnaireDeclineUseCaseFactory,
  deps: [QuestionnaireBannerRepository],
};

/* Questionnaire */
const QuestionnaireQuestionsUseCaseFactory = (
  questionnaireRepository: QuestionnaireRepository,
): GetQuestionnaireQuestionsUseCase =>
  new GetQuestionnaireQuestionsUseCase(questionnaireRepository);
export const getQuestionnaireQuestionsUseCaseProvider = {
  provide: GetQuestionnaireQuestionsUseCase,
  useFactory: QuestionnaireQuestionsUseCaseFactory,
  deps: [QuestionnaireRepository],
};

const QuestionnaireSubmissionUseCaseFactory = (
  questionnaireRepository: QuestionnaireRepository,
): SubmitQuestionnaireAnswersUseCase =>
  new SubmitQuestionnaireAnswersUseCase(questionnaireRepository);
export const submitQuestionnaireAnswersUseCaseProvider = {
  provide: SubmitQuestionnaireAnswersUseCase,
  useFactory: QuestionnaireSubmissionUseCaseFactory,
  deps: [QuestionnaireRepository],
};

/* Auth */

const getPhoneDataUseCaseFactory = (userRepository: UserRepository): GetPhoneDataUseCase =>
  new GetPhoneDataUseCase(userRepository);
export const getPhoneDataUseCaseProvider = {
  provide: GetPhoneDataUseCase,
  useFactory: getPhoneDataUseCaseFactory,
  deps: [userInjectionToken],
};

const SwitchedSelectedMarketUseCaseFactory = (
  userRepository: UserRepository,
): UpdateSwitchedSelectedMarketUseCase => new UpdateSwitchedSelectedMarketUseCase(userRepository);
export const switchedSelectedMarketUseCaseProvider = {
  provide: UpdateSwitchedSelectedMarketUseCase,
  useFactory: SwitchedSelectedMarketUseCaseFactory,
  deps: [userInjectionToken],
};

const getUserCartUseCaseFactory = (cartRepository: CartRepository): GetUserCartUseCase =>
  new GetUserCartUseCase(cartRepository);
export const getUserCartUseCaseProvider = {
  provide: GetUserCartUseCase,
  useFactory: getUserCartUseCaseFactory,
  deps: [cartRepositoryInjectionToken],
};

const removeProductFromCartUseCaseFactory = (
  cartRepository: CartRepository,
): RemoveProductFromCartUseCase => new RemoveProductFromCartUseCase(cartRepository);
export const removeProductFromCartUseCaseProvider = {
  provide: RemoveProductFromCartUseCase,
  useFactory: removeProductFromCartUseCaseFactory,
  deps: [cartRepositoryInjectionToken],
};

const emptyCartUseCaseFactory = (cartRepository: CartRepository): EmptyCartUseCase =>
  new EmptyCartUseCase(cartRepository);
export const emptyCartUseCaseProvider = {
  provide: EmptyCartUseCase,
  useFactory: emptyCartUseCaseFactory,
  deps: [cartRepositoryInjectionToken],
};

const updateProductInCartUseCaseFactory = (
  cartRepository: CartRepository,
): UpdateProductInCartUseCase => new UpdateProductInCartUseCase(cartRepository);
export const updateProductInCartUseCaseProvider = {
  provide: UpdateProductInCartUseCase,
  useFactory: updateProductInCartUseCaseFactory,
  deps: [cartRepositoryInjectionToken],
};
const updateCartUseCaseFactory = (cartRepository: CartRepository): UpdateCartUseCase =>
  new UpdateCartUseCase(cartRepository);
export const updateCartUseCaseProvider = {
  provide: UpdateCartUseCase,
  useFactory: updateCartUseCaseFactory,
  deps: [cartRepositoryInjectionToken],
};

const addToCartUseCaseFactory = (cartRepository: CartRepository): AddToCartUseCase =>
  new AddToCartUseCase(cartRepository);
export const addToCartUseCaseProvider = {
  provide: AddToCartUseCase,
  useFactory: addToCartUseCaseFactory,
  deps: [cartRepositoryInjectionToken],
};

const getDukanShopUseCaseFactory = (
  dukanShopRepository: DukanShopRepository,
): GetDukanShopUseCase => new GetDukanShopUseCase(dukanShopRepository);
export const getDukanShopUseCaseProvider = {
  provide: GetDukanShopUseCase,
  useFactory: getDukanShopUseCaseFactory,
  deps: [dukanShopRepositoryToken],
};
const getDynamicIncentiveProgramUseCaseFactory = (
  dynamicIncentiveProgramRepository: DynamicIncentiveProgramRepository,
): GetDynamicIncentiveProgramUseCase =>
  new GetDynamicIncentiveProgramUseCase(dynamicIncentiveProgramRepository);
export const getDynamicIncentiveProgramUseCaseProvider = {
  provide: GetDynamicIncentiveProgramUseCase,
  useFactory: getDynamicIncentiveProgramUseCaseFactory,
  deps: [DynamicIncentiveProgramRepositoryToken],
};
const getDynamicIncentiveProgramHistoryUseCaseFactory = (
  dynamicIncentiveProgramRepository: DynamicIncentiveProgramRepository,
): GetDynamicIncentiveProgramHistoryUseCase =>
  new GetDynamicIncentiveProgramHistoryUseCase(dynamicIncentiveProgramRepository);
export const getDynamicIncentiveProgramHistoryUseCaseProvider = {
  provide: GetDynamicIncentiveProgramHistoryUseCase,
  useFactory: getDynamicIncentiveProgramHistoryUseCaseFactory,
  deps: [DynamicIncentiveProgramRepositoryToken],
};
const acceptDynamicIncentiveProgramUseCaseFactory = (
  dynamicIncentiveProgramRepository: DynamicIncentiveProgramRepository,
): AcceptDynamicIncentiveProgramUseCase =>
  new AcceptDynamicIncentiveProgramUseCase(dynamicIncentiveProgramRepository);
export const acceptDynamicIncentiveProgramUseCaseProvider = {
  provide: AcceptDynamicIncentiveProgramUseCase,
  useFactory: acceptDynamicIncentiveProgramUseCaseFactory,
  deps: [DynamicIncentiveProgramRepositoryToken],
};
const declineDynamicIncentiveProgramUseCaseFactory = (
  dynamicIncentiveProgramRepository: DynamicIncentiveProgramRepository,
): DeclineDynamicIncentiveProgramUseCase =>
  new DeclineDynamicIncentiveProgramUseCase(dynamicIncentiveProgramRepository);
export const declineDynamicIncentiveProgramUseCaseProvider = {
  provide: DeclineDynamicIncentiveProgramUseCase,
  useFactory: declineDynamicIncentiveProgramUseCaseFactory,
  deps: [DynamicIncentiveProgramRepositoryToken],
};
const feedbackDynamicIncentiveProgramUseCaseFactory = (
  dynamicIncentiveProgramRepository: DynamicIncentiveProgramRepository,
): FeedbackDynamicIncentiveProgramUseCase =>
  new FeedbackDynamicIncentiveProgramUseCase(dynamicIncentiveProgramRepository);
export const feedbackDynamicIncentiveProgramUseCaseProvider = {
  provide: FeedbackDynamicIncentiveProgramUseCase,
  useFactory: feedbackDynamicIncentiveProgramUseCaseFactory,
  deps: [DynamicIncentiveProgramRepositoryToken],
};
const createDukanShopUseCaseFactory = (
  dukanShopRepository: DukanShopRepository,
): CreateDukanShopUseCase => new CreateDukanShopUseCase(dukanShopRepository);
export const createDukanShopUseCaseProvider = {
  provide: CreateDukanShopUseCase,
  useFactory: createDukanShopUseCaseFactory,
  deps: [dukanShopRepositoryToken],
};
const deactivateDukanShopUseCaseFactory = (
  dukanShopRepository: DukanShopRepository,
): DeactivateDukanShopUseCase => new DeactivateDukanShopUseCase(dukanShopRepository);
export const deactivateDukanShopUseCaseProvider = {
  provide: DeactivateDukanShopUseCase,
  useFactory: deactivateDukanShopUseCaseFactory,
  deps: [dukanShopRepositoryToken],
};
const reactivateDukanShopUseCaseFactory = (
  dukanShopRepository: DukanShopRepository,
): ReactivateDukanShopUseCase => new ReactivateDukanShopUseCase(dukanShopRepository);
export const reactivateDukanShopUseCaseProvider = {
  provide: ReactivateDukanShopUseCase,
  useFactory: reactivateDukanShopUseCaseFactory,
  deps: [dukanShopRepositoryToken],
};
const getDukanProductsUseCaseFactory = (
  dukanShopRepository: DukanShopRepository,
): GetDukanProductsUseCase => new GetDukanProductsUseCase(dukanShopRepository);
export const getDukanProductsUseCaseProvider = {
  provide: GetDukanProductsUseCase,
  useFactory: getDukanProductsUseCaseFactory,
  deps: [dukanShopRepositoryToken],
};
const updateDukanUseCaseFactory = (
  dukanShopRepository: DukanShopRepository,
): UpdateDukanInfoUseCase => new UpdateDukanInfoUseCase(dukanShopRepository);
export const updateDukanUseCaseProvider = {
  provide: UpdateDukanInfoUseCase,
  useFactory: updateDukanUseCaseFactory,
  deps: [dukanShopRepositoryToken],
};
const updateDukanAdsIntegrationUseCaseFactory = (
  dukanShopRepository: DukanShopRepository,
): UpdateDukanAdsIntegrationUseCase => new UpdateDukanAdsIntegrationUseCase(dukanShopRepository);
export const updateDukanAdsIntegrationUseCaseProvider = {
  provide: UpdateDukanAdsIntegrationUseCase,
  useFactory: updateDukanAdsIntegrationUseCaseFactory,
  deps: [dukanShopRepositoryToken],
};
const setDukanProductsUseCaseFactory = (
  dukanShopRepository: DukanShopRepository,
): SetDukanProductsUseCase => new SetDukanProductsUseCase(dukanShopRepository);
export const setDukanProductsUseCaseProvider = {
  provide: SetDukanProductsUseCase,
  useFactory: setDukanProductsUseCaseFactory,
  deps: [dukanShopRepositoryToken],
};
const pushDukanProductUseCaseFactory = (
  dukanShopRepository: DukanShopRepository,
): PushDukanProductUseCase => new PushDukanProductUseCase(dukanShopRepository);
export const pushDukanProductUseCaseProvider = {
  provide: PushDukanProductUseCase,
  useFactory: pushDukanProductUseCaseFactory,
  deps: [dukanShopRepositoryToken],
};
const deleteDukanProductUseCaseFactory = (
  dukanShopRepository: DukanShopRepository,
): DeleteDukanProductUseCase => new DeleteDukanProductUseCase(dukanShopRepository);
export const deleteDukanProductUseCaseProvider = {
  provide: DeleteDukanProductUseCase,
  useFactory: deleteDukanProductUseCaseFactory,
  deps: [dukanShopRepositoryToken],
};
const getDukanProductDetailsUseCaseFactory = (
  dukanShopRepository: DukanShopRepository,
): GetDukanProductDetailsUseCase => new GetDukanProductDetailsUseCase(dukanShopRepository);
export const getDukanProductDetailsUseCaseProvider = {
  provide: GetDukanProductDetailsUseCase,
  useFactory: getDukanProductDetailsUseCaseFactory,
  deps: [dukanShopRepositoryToken],
};
const updateProductDetailsUseCaseFactory = (
  dukanShopRepository: DukanShopRepository,
): UpdateDukanProductDetailsUseCase => new UpdateDukanProductDetailsUseCase(dukanShopRepository);
export const updateDukanProductDetailsUseCaseProvider = {
  provide: UpdateDukanProductDetailsUseCase,
  useFactory: updateProductDetailsUseCaseFactory,
  deps: [dukanShopRepositoryToken],
};
const deleteDukanProductMediaUseCaseFactory = (
  dukanShopRepository: DukanShopRepository,
): DeleteDukanProductMediaUseCase => new DeleteDukanProductMediaUseCase(dukanShopRepository);
export const deleteDukanProductMediaUseCaseProvider = {
  provide: DeleteDukanProductMediaUseCase,
  useFactory: deleteDukanProductMediaUseCaseFactory,
  deps: [dukanShopRepositoryToken],
};
const addDukanProductMediaUseCaseFactory = (
  dukanShopRepository: DukanShopRepository,
): AddDukanProductMediaUseCase => new AddDukanProductMediaUseCase(dukanShopRepository);
export const addDukanProductMediaUseCaseProvider = {
  provide: AddDukanProductMediaUseCase,
  useFactory: addDukanProductMediaUseCaseFactory,
  deps: [dukanShopRepositoryToken],
};
const getInsightsUrlUseCaseFactory = (
  merchantInsightsRepository: MerchantInsightsRepository,
): GetInsightsUrlUseCase => new GetInsightsUrlUseCase(merchantInsightsRepository);
export const getInsightsUrlUseCaseProvider = {
  provide: GetInsightsUrlUseCase,
  useFactory: getInsightsUrlUseCaseFactory,
  deps: [merchantInsightsRepositoryToken],
};
const getInsightsDataUseCaseFactory = (
  merchantInsightsRepository: MerchantInsightsRepository,
): GetInsightsDataUseCase => new GetInsightsDataUseCase(merchantInsightsRepository);
export const getInsightsDataUseCaseProvider = {
  provide: GetInsightsDataUseCase,
  useFactory: getInsightsDataUseCaseFactory,
  deps: [merchantInsightsRepositoryToken],
};
const getReferralStepsDataUseCaseFactory = (
  merchantInsightsRepository: ReferralProgramRepository,
): GetReferralStepsDataUseCase => new GetReferralStepsDataUseCase(merchantInsightsRepository);
export const getReferralStepsDataUseCaseProvider = {
  provide: GetReferralStepsDataUseCase,
  useFactory: getReferralStepsDataUseCaseFactory,
  deps: [referralProgramRepositoryToken],
};
const getReferralsProgramUseCaseFactory = (
  merchantInsightsRepository: ReferralProgramRepository,
): GetReferralsProgramUseCase => new GetReferralsProgramUseCase(merchantInsightsRepository);
export const getReferralsProgramUseCaseProvider = {
  provide: GetReferralsProgramUseCase,
  useFactory: getReferralsProgramUseCaseFactory,
  deps: [referralProgramRepositoryToken],
};
const getStockAvailabilityUseCaseFactory = (
  stockAvailabilityRepository: StockAvailabilityRepository,
): GetStockAvailabilityUseCase => new GetStockAvailabilityUseCase(stockAvailabilityRepository);
export const getStockAvailabilityUseCaseProvider = {
  provide: GetStockAvailabilityUseCase,
  useFactory: getStockAvailabilityUseCaseFactory,
  deps: [stockAvailabilityRepositoryToken],
};
const getLearningCenterUseCaseFactory = (
  learningCenterRepository: LearningCenterRepository,
): GetTopicsUseCase => new GetTopicsUseCase(learningCenterRepository);
export const getTopicsUseCaseProvider = {
  provide: GetTopicsUseCase,
  useFactory: getLearningCenterUseCaseFactory,
  deps: [learningCenterRepositoryToken],
};
const getSubTopicUseCaseFactory = (
  learningCenterRepository: LearningCenterRepository,
): GetSubTopicsUseCase => new GetSubTopicsUseCase(learningCenterRepository);
export const getSubTopicsUseCaseProvider = {
  provide: GetSubTopicsUseCase,
  useFactory: getSubTopicUseCaseFactory,
  deps: [learningCenterRepositoryToken],
};
const getContentBySubTopicIdUseCaseFactory = (
  learningCenterRepository: LearningCenterRepository,
): GetContentBySubTopicIdUseCase => new GetContentBySubTopicIdUseCase(learningCenterRepository);
export const getContentBySubTopicIdUseCaseProvider = {
  provide: GetContentBySubTopicIdUseCase,
  useFactory: getContentBySubTopicIdUseCaseFactory,
  deps: [learningCenterRepositoryToken],
};
const getItemByContentIdUseCaseFactory = (
  learningCenterRepository: LearningCenterRepository,
): GetItemByContentIdUseCase => new GetItemByContentIdUseCase(learningCenterRepository);
export const getItemByContentIdUseCaseProvider = {
  provide: GetItemByContentIdUseCase,
  useFactory: getItemByContentIdUseCaseFactory,
  deps: [learningCenterRepositoryToken],
};
const getTopicFromSubTopicIdUseCaseFactory = (
  learningCenterRepository: LearningCenterRepository,
): GetTopicFromSubTopicId => new GetTopicFromSubTopicId(learningCenterRepository);
export const getTopicFromSubTopicIdUseCaseProvider = {
  provide: GetTopicFromSubTopicId,
  useFactory: getTopicFromSubTopicIdUseCaseFactory,
  deps: [learningCenterRepositoryToken],
};
const sendNotifyMeRequestsUseCaseFactory = (
  stockAvailabilityRepository: StockAvailabilityRepository,
): SendNotifyMeRequestsUseCase => new SendNotifyMeRequestsUseCase(stockAvailabilityRepository);
export const sendNotifyMeRequestsUseCaseProvider = {
  provide: SendNotifyMeRequestsUseCase,
  useFactory: sendNotifyMeRequestsUseCaseFactory,
  deps: [stockAvailabilityRepositoryToken],
};
const withdrawIntlBankTransferUseCaseFactory = (
  walletRepository: WalletRepository,
): WithdrawIntlBankTransferUseCase => new WithdrawIntlBankTransferUseCase(walletRepository);
export const withdrawIntlBankTransferUseCaseProvider = {
  provide: WithdrawIntlBankTransferUseCase,
  useFactory: withdrawIntlBankTransferUseCaseFactory,
  deps: [walletRepositoryInjectionToken],
};
const withdrawPayoneerUseCaseFactory = (
  walletRepository: WalletRepository,
): WithdrawPayoneerUseCase => new WithdrawPayoneerUseCase(walletRepository);
export const withdrawPayoneerUseCaseProvider = {
  provide: WithdrawPayoneerUseCase,
  useFactory: withdrawPayoneerUseCaseFactory,
  deps: [walletRepositoryInjectionToken],
};
const createSecurePayoneerUseCaseFactory = (
  walletRepository: WalletRepository,
): CreateSecurePayoneerUseCase => new CreateSecurePayoneerUseCase(walletRepository);
export const createSecurePayoneerUseCaseProvider = {
  provide: CreateSecurePayoneerUseCase,
  useFactory: createSecurePayoneerUseCaseFactory,
  deps: [walletRepositoryInjectionToken],
};

const createSecureIntBankUseCaseFactory = (
  walletRepository: WalletRepository,
): CreateSecureIntlBankTransferUseCase => new CreateSecureIntlBankTransferUseCase(walletRepository);
export const createSecureIntBankUseCaseProvider = {
  provide: CreateSecureIntlBankTransferUseCase,
  useFactory: createSecureIntBankUseCaseFactory,
  deps: [walletRepositoryInjectionToken],
};

const createSecureWithdrawRequestUseCaseFactory = (
  walletRepository: WalletRepository,
): CreateSecureWithdrawalRequestUseCase =>
  new CreateSecureWithdrawalRequestUseCase(walletRepository);
export const createSecureWithdrawRequestUseCaseProvider = {
  provide: CreateSecureWithdrawalRequestUseCase,
  useFactory: createSecureWithdrawRequestUseCaseFactory,
  deps: [walletRepositoryInjectionToken],
};

const requestWithdrawalOtpUseCaseFactory = (
  walletRepository: WalletRepository,
): RequestWithdrawalOtpUseCase => new RequestWithdrawalOtpUseCase(walletRepository);
export const requestWithdrawalOtpUseCaseProvider = {
  provide: RequestWithdrawalOtpUseCase,
  useFactory: requestWithdrawalOtpUseCaseFactory,
  deps: [walletRepositoryInjectionToken],
};

const getCountriesUseCaseFactory = (countryRepository: CountriesRepository): GetCountriesUseCase =>
  new GetCountriesUseCase(countryRepository);
export const getCountriesUseCaseProvider = {
  provide: GetCountriesUseCase,
  useFactory: getCountriesUseCaseFactory,
  deps: [CountriesRepository],
};

const getCountryVATbyIsoCode3UseCaseFactory = (
  countryRepository: CountriesRepository,
): GetCountryVATByIsoCode3UseCase => new GetCountryVATByIsoCode3UseCase(countryRepository);
export const getCountryVATByIsoCode3UseCaseProvider = {
  provide: GetCountryVATByIsoCode3UseCase,
  useFactory: getCountryVATbyIsoCode3UseCaseFactory,
  deps: [CountriesRepository],
};

const getProductVATUseCaseFactory = (
  productsRepository: ProductsRepository,
): GetProductVATUseCase => new GetProductVATUseCase(productsRepository);
export const getProductVATUseCaseProvider = {
  provide: GetProductVATUseCase,
  useFactory: getProductVATUseCaseFactory,
  deps: [ProductsRepository],
};

const SubmitProductReviewUseCaseUseCaseFactory = (
  productsRepository: ProductsRepository,
): SubmitProductReviewUseCase => new SubmitProductReviewUseCase(productsRepository);
export const SubmitProductReviewUseCaseProvider = {
  provide: SubmitProductReviewUseCase,
  useFactory: SubmitProductReviewUseCaseUseCaseFactory,
  deps: [ProductsRepository],
};

const GetVariantsByProductIdsUseCaseFactory = (
  productsRepository: ProductsRepository,
): GetVariantsByProductIdsUseCase => new GetVariantsByProductIdsUseCase(productsRepository);
export const getVariantsByProductIdsUseCaseProvider = {
  provide: GetVariantsByProductIdsUseCase,
  useFactory: GetVariantsByProductIdsUseCaseFactory,
  deps: [ProductsRepository],
};

const logGTagEventUseCaseFactory = (
  analyticsRepository: AnalyticsRepository,
): LogGTagEventUseCase => new LogGTagEventUseCase(analyticsRepository);
const logGTagEventUseCaseProvider = {
  provide: LogGTagEventUseCase,
  useFactory: logGTagEventUseCaseFactory,
  deps: [AnalyticsRepositoryInjectionToken],
};

const logMixpanelEventUseCaseFactory = (
  analyticsRepository: AnalyticsRepository,
): LogMixpanelEventUseCase => new LogMixpanelEventUseCase(analyticsRepository);
const logMixpanelEventUseCaseProvider = {
  provide: LogMixpanelEventUseCase,
  useFactory: logMixpanelEventUseCaseFactory,
  deps: [AnalyticsRepositoryInjectionToken],
};

const getUserCountryUseCaseFactory = (
  userLocationRepository: UserLocationRepository,
): GetUserCountryUseCase => new GetUserCountryUseCase(userLocationRepository);
const getUserCountryUseCaseProvider = {
  provide: GetUserCountryUseCase,
  useFactory: getUserCountryUseCaseFactory,
  deps: [UserLocationRepository],
};

const getUserNotificationsUseCaseFactory = (
  userNotificationRepository: UserNotificationRepository,
): GetUserNotificationsUseCase => new GetUserNotificationsUseCase(userNotificationRepository);
const getUserNotificationsUseCaseProvider = {
  provide: GetUserNotificationsUseCase,
  useFactory: getUserNotificationsUseCaseFactory,
  deps: [UserNotificationRepository],
};

const setUserNotificationsUseCaseFactory = (
  userNotificationRepository: UserNotificationRepository,
): SetUserNotificationsUseCase => new SetUserNotificationsUseCase(userNotificationRepository);
const setUserNotificationsUseCaseProvider = {
  provide: SetUserNotificationsUseCase,
  useFactory: setUserNotificationsUseCaseFactory,
  deps: [UserNotificationRepository],
};

const setNotificationUseCaseFactory = (
  userNotificationRepository: UserNotificationRepository,
): SetNotificationUseCase => new SetNotificationUseCase(userNotificationRepository);
const setNotificationUseCaseProvider = {
  provide: SetNotificationUseCase,
  useFactory: setNotificationUseCaseFactory,
  deps: [UserNotificationRepository],
};

const getOperationRateUseCaseFactory = (
  incentiveProgramRepository: IncentiveProgramRepository,
): GetOperationRateUseCase => new GetOperationRateUseCase(incentiveProgramRepository);
const getOperationRateUseCaseProvider = {
  provide: GetOperationRateUseCase,
  useFactory: getOperationRateUseCaseFactory,
  deps: [IncentiveProgramRepository],
};
const getPreorderableProductsUseCaseFactory = (
  productsRepository: ProductsRepository,
): GetPreorderableProductsUseCase => new GetPreorderableProductsUseCase(productsRepository);
export const getPreorderableProductsUseCaseProvider = {
  provide: GetPreorderableProductsUseCase,
  useFactory: getPreorderableProductsUseCaseFactory,
  deps: [ProductsRepository],
};

const getUpsellableProductsUseCaseFactory = (
  productsRepository: ProductsRepository,
): GetUpsellableProductsUseCase => new GetUpsellableProductsUseCase(productsRepository);
export const getUpsellableProductsUseCaseProvider = {
  provide: GetUpsellableProductsUseCase,
  useFactory: getUpsellableProductsUseCaseFactory,
  deps: [ProductsRepository],
};

const shouldDisplayNewHomePageUseCaseFactory = (
  productsRepository: ProductsRepository,
): ShouldDisplayNewHomePageUseCase => new ShouldDisplayNewHomePageUseCase(productsRepository);
export const shouldDisplayNewHomePageUseCaseProvider = {
  provide: ShouldDisplayNewHomePageUseCase,
  useFactory: shouldDisplayNewHomePageUseCaseFactory,
  deps: [ProductsRepository],
};

const checkUserNewExperienceEligibilityUseCaseFactory = (
  productsRepository: ProductsRepository,
): CheckUserNewExperienceEligibilityUseCase =>
  new CheckUserNewExperienceEligibilityUseCase(productsRepository);
export const checkUserNewExperienceEligibilityUseCaseProvider = {
  provide: CheckUserNewExperienceEligibilityUseCase,
  useFactory: checkUserNewExperienceEligibilityUseCaseFactory,
  deps: [ProductsRepository],
};

const listenToProductsPageExperienceChangesUsecaseFactory = (
  productsRepository: ProductsRepository,
): ListenToProductsPageExperienceChangesUseCase =>
  new ListenToProductsPageExperienceChangesUseCase(productsRepository);
export const listenToProductsPageExperienceChangesUsecaseProvider = {
  provide: ListenToProductsPageExperienceChangesUseCase,
  useFactory: listenToProductsPageExperienceChangesUsecaseFactory,
  deps: [ProductsRepository],
};

const setShouldDisplayNewHomePageUseCaseFactory = (
  productsRepository: ProductsRepository,
): SetSelectedProductsPageExperienceUseCase =>
  new SetSelectedProductsPageExperienceUseCase(productsRepository);

export const setShouldDisplayNewHomePageUseCaseProvider = {
  provide: SetSelectedProductsPageExperienceUseCase,
  useFactory: setShouldDisplayNewHomePageUseCaseFactory,
  deps: [ProductsRepository],
};

const getProductCreativesEligibilityUseCaseFactory = (
  productsRepository: ProductsRepository,
): GetProductCreativesEligibilityUseCase =>
  new GetProductCreativesEligibilityUseCase(productsRepository);

export const getProductCreativesEligibilityUseCaseProvider = {
  provide: GetProductCreativesEligibilityUseCase,
  useFactory: getProductCreativesEligibilityUseCaseFactory,
  deps: [ProductsRepository],
};

const createBulkPreOrderRequestUseCaseFactory = (
  bulkPreOrderRepository: BulkPreOrderRepository,
): CreateBulkPreOrderRequestUseCase => new CreateBulkPreOrderRequestUseCase(bulkPreOrderRepository);
export const createBulkPreOrderRequestUseCaseProvider = {
  provide: CreateBulkPreOrderRequestUseCase,
  useFactory: createBulkPreOrderRequestUseCaseFactory,
  deps: [BulkPreOrderRepository],
};

const getPreOrderBenefitsUseCaseFactory = (
  bulkPreOrderRepository: BulkPreOrderRepository,
): GetPreOrderBenefitsUseCase => new GetPreOrderBenefitsUseCase(bulkPreOrderRepository);
export const getPreOrderBenefitsUseCaseProvider = {
  provide: GetPreOrderBenefitsUseCase,
  useFactory: getPreOrderBenefitsUseCaseFactory,
  deps: [BulkPreOrderRepository],
};

const getPreOrderCommercialCategoriesUseCaseFactory = (
  bulkPreOrderRepository: BulkPreOrderRepository,
): GetPreOrderCommercialCategoriesUseCase =>
  new GetPreOrderCommercialCategoriesUseCase(bulkPreOrderRepository);
export const getPreOrderCommercialCategoriesUseProvider = {
  provide: GetPreOrderCommercialCategoriesUseCase,
  useFactory: getPreOrderCommercialCategoriesUseCaseFactory,
  deps: [BulkPreOrderRepository],
};

const getPreOrderRequestsSummaryUseCaseFactory = (
  bulkPreOrderRepository: BulkPreOrderRepository,
): GetPreOrderRequestsSummaryUseCase =>
  new GetPreOrderRequestsSummaryUseCase(bulkPreOrderRepository);
export const getPreOrderRequestsSummaryUseCaseProvider = {
  provide: GetPreOrderRequestsSummaryUseCase,
  useFactory: getPreOrderRequestsSummaryUseCaseFactory,
  deps: [BulkPreOrderRepository],
};

const getBulkPreOrderRequestsUseCaseFactory = (
  bulkPreOrderRepository: BulkPreOrderRepository,
): GetBulkPreOrderRequestsUseCase => new GetBulkPreOrderRequestsUseCase(bulkPreOrderRepository);
export const getBulkPreOrderRequestsUseCaseProvider = {
  provide: GetBulkPreOrderRequestsUseCase,
  useFactory: getBulkPreOrderRequestsUseCaseFactory,
  deps: [BulkPreOrderRepository],
};

const acceptPreOrderDownPaymentUseCaseFactory = (
  bulkPreOrderRepository: BulkPreOrderRepository,
): AcceptPreOrderDownPaymentUseCase => new AcceptPreOrderDownPaymentUseCase(bulkPreOrderRepository);
export const acceptPreOrderDownPaymentUseCaseProvider = {
  provide: AcceptPreOrderDownPaymentUseCase,
  useFactory: acceptPreOrderDownPaymentUseCaseFactory,
  deps: [BulkPreOrderRepository],
};

const rejectPreOrderDownPaymentUseCaseFactory = (
  bulkPreOrderRepository: BulkPreOrderRepository,
): RejectPreOrderDownPaymentUseCase => new RejectPreOrderDownPaymentUseCase(bulkPreOrderRepository);
export const rejectPreOrderDownPaymentUseCaseProvider = {
  provide: RejectPreOrderDownPaymentUseCase,
  useFactory: rejectPreOrderDownPaymentUseCaseFactory,
  deps: [BulkPreOrderRepository],
};

const setUserAcquisitionChannelUseCaseFactory = (
  analyticsRepository: AnalyticsRepository,
): SetUserAcquisitionChannelUseCase => new SetUserAcquisitionChannelUseCase(analyticsRepository);
const setUserAcquisitionChannelUseCaseProvider = {
  provide: SetUserAcquisitionChannelUseCase,
  useFactory: setUserAcquisitionChannelUseCaseFactory,
  deps: [AnalyticsRepositoryInjectionToken],
};

const getUserIPAddressUseCaseFactory = (
  userLocationRepository: UserLocationRepository,
): GetUserIPAddressUseCase => new GetUserIPAddressUseCase(userLocationRepository);
const getUserIPAddressUseCaseProvider = {
  provide: GetUserIPAddressUseCase,
  useFactory: getUserIPAddressUseCaseFactory,
  deps: [UserLocationRepository],
};

const getOrderSummaryUseCaseFactory = (userRepository: UserRepository): GetOrderSummaryUseCase =>
  new GetOrderSummaryUseCase(userRepository);
const getOrderSummaryUseCaseProvider = {
  provide: GetOrderSummaryUseCase,
  useFactory: getOrderSummaryUseCaseFactory,
  deps: [userInjectionToken],
};

const getUserProfileUseCaseFactory = (userRepository: UserRepository): GetUserProfileUseCase =>
  new GetUserProfileUseCase(userRepository);
const getUserProfileUseCaseProvider = {
  provide: GetUserProfileUseCase,
  useFactory: getUserProfileUseCaseFactory,
  deps: [userInjectionToken],
};

const LinkMerchantStoreUseCaseFactory = (
  _merchantStoreRepository: MerchantStoreRepository,
): LinkMerchantStoreUseCase => new LinkMerchantStoreUseCase(_merchantStoreRepository);
const LinkMerchantStoreUseCaseProvider = {
  provide: LinkMerchantStoreUseCase,
  useFactory: LinkMerchantStoreUseCaseFactory,
  deps: [MerchantStoreRepositoryInjectionToken],
};

const UnlinkMerchantStoreUseCaseFactory = (
  _merchantStoreRepository: MerchantStoreRepository,
): UnlinkMerchantStoreUseCase => new UnlinkMerchantStoreUseCase(_merchantStoreRepository);
const UnlinkMerchantStoreUseCaseProvider = {
  provide: UnlinkMerchantStoreUseCase,
  useFactory: UnlinkMerchantStoreUseCaseFactory,
  deps: [MerchantStoreRepositoryInjectionToken],
};

const GetStoresUseCaseFactory = (
  _merchantStoreRepository: MerchantStoreRepository,
): GetStoresUseCase => new GetStoresUseCase(_merchantStoreRepository);
const GetStoresUseCaseProvider = {
  provide: GetStoresUseCase,
  useFactory: GetStoresUseCaseFactory,
  deps: [MerchantStoreRepositoryInjectionToken],
};

const GetUserStoresUseCaseFactory = (
  _merchantStoreRepository: MerchantStoreRepository,
): GetUserStoresUseCase => new GetUserStoresUseCase(_merchantStoreRepository);
const GetUserStoresUseCaseProvider = {
  provide: GetUserStoresUseCase,
  useFactory: GetUserStoresUseCaseFactory,
  deps: [MerchantStoreRepositoryInjectionToken],
};

const AddProductToStoreUseCaseFactory = (
  _merchantStoreRepository: MerchantStoreRepository,
): AddProductToStoreUseCase => new AddProductToStoreUseCase(_merchantStoreRepository);
const AddProductToStoreUseCaseProvider = {
  provide: AddProductToStoreUseCase,
  useFactory: AddProductToStoreUseCaseFactory,
  deps: [MerchantStoreRepositoryInjectionToken],
};

const GenerateAPIKeyUseCaseFactory = (
  _merchantStoreRepository: MerchantStoreRepository,
): GenerateAPIKeyUseCase => new GenerateAPIKeyUseCase(_merchantStoreRepository);
const GenerateAPIKeyUseCaseProvider = {
  provide: GenerateAPIKeyUseCase,
  useFactory: GenerateAPIKeyUseCaseFactory,
  deps: [MerchantStoreRepositoryInjectionToken],
};

const GetAPIKeysUseCaseFactory = (
  _merchantStoreRepository: MerchantStoreRepository,
): GetAPIKeysUseCase => new GetAPIKeysUseCase(_merchantStoreRepository);
const GetAPIKeysUseCaseProvider = {
  provide: GetAPIKeysUseCase,
  useFactory: GetAPIKeysUseCaseFactory,
  deps: [MerchantStoreRepositoryInjectionToken],
};

const setUserReferralUseCaseFactory = (
  analyticsRepository: AnalyticsRepository,
): SetUserReferralUseCase => new SetUserReferralUseCase(analyticsRepository);
const setUserReferralUseCaseProvider = {
  provide: SetUserReferralUseCase,
  useFactory: setUserReferralUseCaseFactory,
  deps: [AnalyticsRepositoryInjectionToken],
};

const initializeMixpanelUseCaseFactory = (
  analyticsRepository: AnalyticsRepository,
): InitializeMixpanelUseCase => new InitializeMixpanelUseCase(analyticsRepository);
const initializeMixpanelUseCaseProvider = {
  provide: InitializeMixpanelUseCase,
  useFactory: initializeMixpanelUseCaseFactory,
  deps: [AnalyticsRepositoryInjectionToken],
};

const identifyMixpanelInstanceUseCaseFactory = (
  analyticsRepository: AnalyticsRepository,
): IdentifyMixpanelInstanceUseCase => new IdentifyMixpanelInstanceUseCase(analyticsRepository);
const identifyMixpanelInstanceUseCaseProvider = {
  provide: IdentifyMixpanelInstanceUseCase,
  useFactory: identifyMixpanelInstanceUseCaseFactory,
  deps: [AnalyticsRepositoryInjectionToken],
};

const resetMixpanelInstanceUseCaseFactory = (
  analyticsRepository: AnalyticsRepository,
): ResetMixpanelInstanceUsecase => new ResetMixpanelInstanceUsecase(analyticsRepository);
const resetMixpanelInstanceUsecaseUseCaseProvider = {
  provide: ResetMixpanelInstanceUsecase,
  useFactory: resetMixpanelInstanceUseCaseFactory,
  deps: [AnalyticsRepositoryInjectionToken],
};

const getRemoteConfigUsecaseFactory = (
  remoteConfigRepository: RemoteConfigRepository,
): GetRemoteConfigUsecase => new GetRemoteConfigUsecase(remoteConfigRepository);
const getRemoteConfigUsecaseProvider = {
  provide: GetRemoteConfigUsecase,
  useFactory: getRemoteConfigUsecaseFactory,
  deps: [RemoteConfigRepository],
};

const getWalletTransactionHistoryFactory = (
  walletRepository: WalletRepository,
): GetWalletTransactionHistoryUseCase => new GetWalletTransactionHistoryUseCase(walletRepository);
const getWalletTransactionHistoryProvider = {
  provide: GetWalletTransactionHistoryUseCase,
  useFactory: getWalletTransactionHistoryFactory,
  deps: [walletRepositoryInjectionToken],
};

const getTransactionsSheetFactory = (
  walletRepository: WalletRepository,
): GetTransactionsSheetUseCase => new GetTransactionsSheetUseCase(walletRepository);
const getTransactionsSheetProvider = {
  provide: GetTransactionsSheetUseCase,
  useFactory: getTransactionsSheetFactory,
  deps: [walletRepositoryInjectionToken],
};

const walletLoginFactory = (walletRepository: WalletRepository): WalletLoginUseCase =>
  new WalletLoginUseCase(walletRepository);
const walletLoginProvider = {
  provide: WalletLoginUseCase,
  useFactory: walletLoginFactory,
  deps: [walletRepositoryInjectionToken],
};

const forgotWalletPasswordFactory = (
  walletRepository: WalletRepository,
): ForgotWalletPasswordUseCase => new ForgotWalletPasswordUseCase(walletRepository);
const forgotWalletPasswordProvider = {
  provide: ForgotWalletPasswordUseCase,
  useFactory: forgotWalletPasswordFactory,
  deps: [walletRepositoryInjectionToken],
};

const changeWalletPasswordUseCaseFactory = (
  walletRepository: WalletRepository,
): ChangeWalletPasswordUseCase => new ChangeWalletPasswordUseCase(walletRepository);
export const changeWalletPasswordProvider = {
  provide: ChangeWalletPasswordUseCase,
  useFactory: changeWalletPasswordUseCaseFactory,
  deps: [walletRepositoryInjectionToken],
};

const resetWalletPasswordUseCaseFactory = (
  walletRepository: WalletRepository,
): ResetWalletPasswordUseCase => new ResetWalletPasswordUseCase(walletRepository);
export const resetWalletPasswordProvider = {
  provide: ResetWalletPasswordUseCase,
  useFactory: resetWalletPasswordUseCaseFactory,
  deps: [walletRepositoryInjectionToken],
};

const getWalletsUseCaseFactory = (walletRepository: WalletRepository): GetWalletsUseCase =>
  new GetWalletsUseCase(walletRepository);
export const getWalletsUseCaseProvider = {
  provide: GetWalletsUseCase,
  useFactory: getWalletsUseCaseFactory,
  deps: [walletRepositoryInjectionToken],
};

const createWithdrawRequestUseCaseFactory = (
  walletRepository: WalletRepository,
): CreateWithdrawalRequestUseCase => new CreateWithdrawalRequestUseCase(walletRepository);
export const createWithdrawRequestUseCaseProvider = {
  provide: CreateWithdrawalRequestUseCase,
  useFactory: createWithdrawRequestUseCaseFactory,
  deps: [walletRepositoryInjectionToken],
};

const getWithdrawalsListUseCaseFactory = (
  walletRepository: WalletRepository,
): GetWithdrawalsListUseCase => new GetWithdrawalsListUseCase(walletRepository);
export const getWithdrawalsListUseCaseProvider = {
  provide: GetWithdrawalsListUseCase,
  useFactory: getWithdrawalsListUseCaseFactory,
  deps: [walletRepositoryInjectionToken],
};

const getLeadsUsecaseFactory = (leadsRepository: LeadsRepository): GetLeadsListUseCase =>
  new GetLeadsListUseCase(leadsRepository);
const getLeadListUsecaseProvider = {
  provide: GetLeadsListUseCase,
  useFactory: getLeadsUsecaseFactory,
  deps: [leadsRepositoryInjectionToken],
};

const calculateOrderCostUseCaseFactory = (
  _orderRepository: OrderRepository,
): CalculateOrderCostUseCase => new CalculateOrderCostUseCase(_orderRepository);
const calculateOrderCostUseCaseProvider = {
  provide: CalculateOrderCostUseCase,
  useFactory: calculateOrderCostUseCaseFactory,
  deps: [orderRepositoryInjectionToken],
};

const orderProductPricesUseCaseFactory = (
  _orderRepository: OrderRepository,
): GetOrderProductPricesUseCase => new GetOrderProductPricesUseCase(_orderRepository);
const getOrderProductPricesUseCaseProvider = {
  provide: GetOrderProductPricesUseCase,
  useFactory: orderProductPricesUseCaseFactory,
  deps: [orderRepositoryInjectionToken],
};

const makeOrderByCartUseCaseFactory = (_orderRepository: OrderRepository): MakeOrderByCartUseCase =>
  new MakeOrderByCartUseCase(_orderRepository);
const makeOrderByCartUseCaseProvider = {
  provide: MakeOrderByCartUseCase,
  useFactory: makeOrderByCartUseCaseFactory,
  deps: [orderRepositoryInjectionToken],
};

const rateOrderUseCaseFactory = (_orderRepository: OrderRepository): RateOrderUseCase =>
  new RateOrderUseCase(_orderRepository);
const rateOrderUseCaseProvider = {
  provide: RateOrderUseCase,
  useFactory: rateOrderUseCaseFactory,
  deps: [orderRepositoryInjectionToken],
};

const getOrderActivityWithStatusUseCaseFactory = (
  _orderRepository: OrderRepository,
): GetOrderActivityWithStatusUseCase => new GetOrderActivityWithStatusUseCase(_orderRepository);
const getOrderActivityWithStatusUseCaseProvider = {
  provide: GetOrderActivityWithStatusUseCase,
  useFactory: getOrderActivityWithStatusUseCaseFactory,
  deps: [orderRepositoryInjectionToken],
};

const cancelOrderUseCaseFactory = (_orderRepository: OrderRepository): CancelOrderUseCase =>
  new CancelOrderUseCase(_orderRepository);
const cancelOrderUseCaseProvider = {
  provide: CancelOrderUseCase,
  useFactory: cancelOrderUseCaseFactory,
  deps: [orderRepositoryInjectionToken],
};

const getChildOrdersUseCaseFactory = (_orderRepository: OrderRepository): GetChildOrdersUseCase =>
  new GetChildOrdersUseCase(_orderRepository);
const getChildOrdersUseCaseProvider = {
  provide: GetChildOrdersUseCase,
  useFactory: getChildOrdersUseCaseFactory,
  deps: [orderRepositoryInjectionToken],
};

const getFailedOrdersUseCaseFactory = (_orderRepository: OrderRepository): GetFailedOrdersUseCase =>
  new GetFailedOrdersUseCase(_orderRepository);
const getFailedOrdersUseCaseProvider = {
  provide: GetFailedOrdersUseCase,
  useFactory: getFailedOrdersUseCaseFactory,
  deps: [orderRepositoryInjectionToken],
};

const getOrdersListUseCaseFactory = (_orderRepository: OrderRepository): GetOrdersListUseCase =>
  new GetOrdersListUseCase(_orderRepository);
const getOrdersListUseCaseProvider = {
  provide: GetOrdersListUseCase,
  useFactory: getOrdersListUseCaseFactory,
  deps: [orderRepositoryInjectionToken],
};

const getOrderStatusListUseCaseFactory = (
  _orderRepository: OrderRepository,
): GetOrderStatusListUseCase => new GetOrderStatusListUseCase(_orderRepository);
const getOrderStatusListUseCaseProvider = {
  provide: GetOrderStatusListUseCase,
  useFactory: getOrderStatusListUseCaseFactory,
  deps: [orderRepositoryInjectionToken],
};

const trackOrderActivityUseCaseFactory = (
  _orderRepository: OrderRepository,
): TrackOrderActivityUseCase => new TrackOrderActivityUseCase(_orderRepository);
const trackOrderActivityUseCaseProvider = {
  provide: TrackOrderActivityUseCase,
  useFactory: trackOrderActivityUseCaseFactory,
  deps: [orderRepositoryInjectionToken],
};

const cpaCalculatorUseCaseFactory = (
  pricingCalculatorRepository: PricingCalculatorRepository,
): CPACalculatorUseCase => new CPACalculatorUseCase(pricingCalculatorRepository);
export const cpaCalculatorUseCaseProvider = {
  provide: CPACalculatorUseCase,
  useFactory: cpaCalculatorUseCaseFactory,
  deps: [pricingCalculatorRepositoryToken],
};

const getProvinceListUseCaseFactory = (
  provinceRepository: ProvinceRepository,
): GetProvinceListUseCase => new GetProvinceListUseCase(provinceRepository);
export const getProvinceListUseCaseProvider = {
  provide: GetProvinceListUseCase,
  useFactory: getProvinceListUseCaseFactory,
  deps: [ProvinceRepositoryToken],
};

const getProvinceZoneUseCaseFactory = (
  provinceRepository: ProvinceRepository,
): GetProvinceZonesUseCase => new GetProvinceZonesUseCase(provinceRepository);
export const getProvinceZonesUseCaseProvider = {
  provide: GetProvinceZonesUseCase,
  useFactory: getProvinceZoneUseCaseFactory,
  deps: [ProvinceRepositoryToken],
};

const getProvinceZoneDistrictUseCaseFactory = (
  provinceRepository: ProvinceRepository,
): GetProvinceZoneDistrictsUseCase => new GetProvinceZoneDistrictsUseCase(provinceRepository);
export const getProvinceZoneDistrictsUseCaseProvider = {
  provide: GetProvinceZoneDistrictsUseCase,
  useFactory: getProvinceZoneDistrictUseCaseFactory,
  deps: [ProvinceRepositoryToken],
};

const isFeatureEnabledUseCaseFactory = (
  marketAvailabilityRepository: MarketAvailabilityRepository,
): IsFeatureEnabledUseCase => new IsFeatureEnabledUseCase(marketAvailabilityRepository);

export const isFeatureEnabledUseCaseProvider = {
  provide: IsFeatureEnabledUseCase,
  useFactory: isFeatureEnabledUseCaseFactory,
  deps: [marketAvailabilityRepositoryToken],
};

const getClosingReasonUseCaseFactory = (
  marketAvailabilityRepository: MarketAvailabilityRepository,
): GetMarketClosingReasonUseCase => new GetMarketClosingReasonUseCase(marketAvailabilityRepository);

export const getClosingReasonUseCaseProvider = {
  provide: GetMarketClosingReasonUseCase,
  useFactory: getClosingReasonUseCaseFactory,
  deps: [marketAvailabilityRepositoryToken],
};

const getLoyaltyTiersDataUseCase = (
  _loyaltyProgramRepository: LoyaltyProgramRepository,
): GetLoyaltyTiersDataUseCase => new GetLoyaltyTiersDataUseCase(_loyaltyProgramRepository);

export const getLoyaltyTiersDataUseCaseProvider = {
  provide: GetLoyaltyTiersDataUseCase,
  useFactory: getLoyaltyTiersDataUseCase,
  deps: [LoyaltyProgramRepositoryToken],
};

const getLoyaltyFAQsDataUseCaseFactory = (
  _loyaltyProgramRepository: LoyaltyProgramRepository,
): GetLoyaltyFAQsDataUseCase => new GetLoyaltyFAQsDataUseCase(_loyaltyProgramRepository);

export const getLoyaltyFAQsDataUseCaseProvider = {
  provide: GetLoyaltyFAQsDataUseCase,
  useFactory: getLoyaltyFAQsDataUseCaseFactory,
  deps: [LoyaltyProgramRepositoryToken],
};

const getLoyaltyProgressUseCaseFactory = (
  _loyaltyProgramRepository: LoyaltyProgramRepository,
): GetLoyaltyProgressUseCase => new GetLoyaltyProgressUseCase(_loyaltyProgramRepository);

export const getLoyaltyProgressCaseProvider = {
  provide: GetLoyaltyProgressUseCase,
  useFactory: getLoyaltyProgressUseCaseFactory,
  deps: [LoyaltyProgramRepositoryToken],
};

const createProductCheckoutUseCaseFactory = (
  _productsRepository: ProductsRepository,
): CreateProductCheckoutUseCase => new CreateProductCheckoutUseCase(_productsRepository);

export const createProductCheckoutCaseProvider = {
  provide: CreateProductCheckoutUseCase,
  useFactory: createProductCheckoutUseCaseFactory,
  deps: [ProductsRepository],
};

const getProductCheckoutDetailUseCaseFactory = (
  _productsRepository: ProductsRepository,
): GetProductCheckoutDetailUseCase => new GetProductCheckoutDetailUseCase(_productsRepository);

export const getProductCheckoutDetailCaseProvider = {
  provide: GetProductCheckoutDetailUseCase,
  useFactory: getProductCheckoutDetailUseCaseFactory,
  deps: [ProductsRepository],
};

const updateProductCheckoutUseCaseFactory = (
  _productsRepository: ProductsRepository,
): UpdateProductCheckoutUseCase => new UpdateProductCheckoutUseCase(_productsRepository);

export const updateProductCheckoutCaseProvider = {
  provide: UpdateProductCheckoutUseCase,
  useFactory: updateProductCheckoutUseCaseFactory,
  deps: [ProductsRepository],
};

const uploadImageUseCaseFactory = (utilitiesRepository: UtilitiesRepository) =>
  new UploadImageUseCase(utilitiesRepository);
export const uploadImageUseCaseProvider = {
  provide: UploadImageUseCase,
  useFactory: uploadImageUseCaseFactory,
  deps: [UtilitiesRepository],
};

@NgModule({
  imports: [CommonModule, HttpClientModule, SocialLoginModule],
  providers: [
    getIncentiveProgramDetailsUseCaseProvider,
    getNewIncentiveProgramDetailsUseCaseProvider,
    getFeatureFlagUseCaseProvider,
    getFeatureAttributeUseCaseProvider,
    getReferralsUseCaseProvider,
    deleteAccountUseCaseProvider,
    changeWalletPasswordProvider,
    updateProfilePictureUseCaseProvider,
    setUserUseCaseProvider,
    changePasswordUseCaseProvider,
    updateProfileUseCaseProvider,
    checkUserFeatureExistsUseCaseProvider,
    GetCommercialCategoriesUseCaseProvider,
    GetCategoryHierarchyUseCaseProvider,
    GetBottomLevelCategoriesUseCaseProvider,
    GetCommercialCategoryDataByIdUseCaseProvider,
    getQuestionnaireEligibilityUseCaseProvider,
    postDeclineQuestionnaireUseCaseProvider,
    getQuestionnaireQuestionsUseCaseProvider,
    submitQuestionnaireAnswersUseCaseProvider,
    withdrawIntlBankTransferUseCaseProvider,
    withdrawPayoneerUseCaseProvider,
    createSecurePayoneerUseCaseProvider,
    createSecureIntBankUseCaseProvider,
    createSecureWithdrawRequestUseCaseProvider,
    requestWithdrawalOtpUseCaseProvider,
    getOperationRateUseCaseProvider,
    SubmitProductReviewUseCaseProvider,
    getUpsellableProductsUseCaseProvider,
    getRemoteConfigUsecaseProvider,
    shouldDisplayNewHomePageUseCaseProvider,
    listenToProductsPageExperienceChangesUsecaseProvider,
    setShouldDisplayNewHomePageUseCaseProvider,
    getProductCreativesEligibilityUseCaseProvider,
    checkUserNewExperienceEligibilityUseCaseProvider,
    getProductCheckoutDetailCaseProvider,
    updateProductCheckoutCaseProvider,

    /* Bulk pre-orders */
    getPreorderableProductsUseCaseProvider,
    createBulkPreOrderRequestUseCaseProvider,
    getBulkPreOrderRequestsUseCaseProvider,
    acceptPreOrderDownPaymentUseCaseProvider,
    rejectPreOrderDownPaymentUseCaseProvider,
    getPreOrderBenefitsUseCaseProvider,
    getPreOrderRequestsSummaryUseCaseProvider,
    getPreOrderCommercialCategoriesUseProvider,

    /* Auth V2 */
    loginAuthUserUseCaseProvider,
    loginUserWithGoogleUseCaseProvider,
    registerUseCaseProvider,
    requestOTPUseCaseProvider,
    requestEmailOTPUseCaseProvider,
    verifyPhoneNumberUseCaseProvider,
    verifyEmailOTPUseCaseProvider,
    completeUserProfileUseCaseProvider,
    getRegisterTokenUseCaseProvider,
    setRegisterTokenUseCaseProvider,
    getPhoneDataUseCaseProvider,
    logoutUseCaseProvider,
    switchedSelectedMarketUseCaseProvider,
    getOrderSummaryUseCaseProvider,
    getUserUseCaseProvider2,
    getUserSuperPropertiesUseCaseProvider,
    forgotPasswordUseCaseProvider,
    resetPasswordUseCaseProvider,
    getUserProfileUseCaseProvider,
    createProductCheckoutCaseProvider,

    /* Dukan Shop */
    getDukanShopUseCaseProvider,
    createDukanShopUseCaseProvider,
    deactivateDukanShopUseCaseProvider,
    reactivateDukanShopUseCaseProvider,
    getDukanProductsUseCaseProvider,
    updateDukanUseCaseProvider,
    updateDukanAdsIntegrationUseCaseProvider,
    setDukanProductsUseCaseProvider,
    pushDukanProductUseCaseProvider,
    deleteDukanProductUseCaseProvider,
    getDukanProductDetailsUseCaseProvider,
    updateDukanProductDetailsUseCaseProvider,
    deleteDukanProductMediaUseCaseProvider,
    addDukanProductMediaUseCaseProvider,

    /* Merchant Insights */
    getInsightsUrlUseCaseProvider,
    getInsightsDataUseCaseProvider,

    /** Loyalty Program  */
    getLoyaltyTiersDataUseCaseProvider,
    getLoyaltyFAQsDataUseCaseProvider,
    getLoyaltyProgressCaseProvider,
    /* Referral Program */
    getReferralStepsDataUseCaseProvider,
    getReferralsProgramUseCaseProvider,

    /* Dynamic Incentive Program */
    getDynamicIncentiveProgramUseCaseProvider,
    acceptDynamicIncentiveProgramUseCaseProvider,
    declineDynamicIncentiveProgramUseCaseProvider,
    feedbackDynamicIncentiveProgramUseCaseProvider,
    getDynamicIncentiveProgramHistoryUseCaseProvider,

    /* Stock Availability */
    getStockAvailabilityUseCaseProvider,
    sendNotifyMeRequestsUseCaseProvider,

    getTopicsUseCaseProvider,
    getSubTopicsUseCaseProvider,
    getContentBySubTopicIdUseCaseProvider,
    getItemByContentIdUseCaseProvider,
    getTopicFromSubTopicIdUseCaseProvider,

    /* Countries */
    getCountriesUseCaseProvider,
    getCountryVATByIsoCode3UseCaseProvider,
    getProductVATUseCaseProvider,

    /* Analytics */
    logGTagEventUseCaseProvider,
    logMixpanelEventUseCaseProvider,
    setUserAcquisitionChannelUseCaseProvider,
    setUserReferralUseCaseProvider,
    initializeMixpanelUseCaseProvider,
    identifyMixpanelInstanceUseCaseProvider,
    resetMixpanelInstanceUsecaseUseCaseProvider,

    /* Location */
    getUserCountryUseCaseProvider,
    getUserIPAddressUseCaseProvider,

    /* Merchant Store  */
    LinkMerchantStoreUseCaseProvider,
    AddProductToStoreUseCaseProvider,
    GetStoresUseCaseProvider,
    GetUserStoresUseCaseProvider,
    UnlinkMerchantStoreUseCaseProvider,
    GenerateAPIKeyUseCaseProvider,
    GetAPIKeysUseCaseProvider,

    /* Cart Products */
    getUserCartUseCaseProvider,
    removeProductFromCartUseCaseProvider,
    emptyCartUseCaseProvider,
    updateProductInCartUseCaseProvider,
    updateCartUseCaseProvider,
    addToCartUseCaseProvider,

    /* Variants */
    getVariantsByProductIdsUseCaseProvider,

    /* Wallet */
    getWalletTransactionHistoryProvider,
    getTransactionsSheetProvider,
    walletLoginProvider,
    forgotWalletPasswordProvider,
    resetWalletPasswordProvider,
    getWalletsUseCaseProvider,
    createWithdrawRequestUseCaseProvider,
    getWithdrawalsListUseCaseProvider,

    /* Leads */
    getLeadListUsecaseProvider,

    /* Orders */
    calculateOrderCostUseCaseProvider,
    makeOrderByCartUseCaseProvider,
    rateOrderUseCaseProvider,
    getOrderActivityWithStatusUseCaseProvider,
    cancelOrderUseCaseProvider,
    getChildOrdersUseCaseProvider,
    getOrderProductPricesUseCaseProvider,
    getFailedOrdersUseCaseProvider,
    getOrdersListUseCaseProvider,
    trackOrderActivityUseCaseProvider,
    getOrderStatusListUseCaseProvider,

    /* Pricing Calculator */
    cpaCalculatorUseCaseProvider,

    /* Province */
    getProvinceListUseCaseProvider,
    getProvinceZonesUseCaseProvider,
    getProvinceZoneDistrictsUseCaseProvider,

    /** Market Availability */
    isFeatureEnabledUseCaseProvider,
    getClosingReasonUseCaseProvider,

    /** Utilities */
    uploadImageUseCaseProvider,

    /** Notifications */
    getUserNotificationsUseCaseProvider,
    setUserNotificationsUseCaseProvider,
    setNotificationUseCaseProvider,

    {
      provide: QuestionnaireRepository,
      useClass: QuestionnaireRepositoryImplementation,
    },
    {
      provide: learningCenterRepositoryToken,
      useClass: LearningCenterRepositoryImpl,
    },
    {
      provide: QuestionnaireBannerRepository,
      useClass: BannerQuestionnaireRepositoryImplementation,
    },
    { provide: RemoteConfigRepository, useClass: RemoteConfigRepositoryImpl },
    {
      provide: IncentiveProgramRepository,
      useClass: IncentiveProgramRepositoryImpl,
    },
    {
      provide: NewIncentiveProgramRepository,
      useClass: NewIncentiveProgramRepositoryImpl,
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.GOOGLE_APP_ID),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    {
      provide: MerchantStoreRepositoryInjectionToken,
      useClass: MerchantStoreRepositoryImplementation,
    },
    {
      provide: commercialCategoriesRepositoryToken,
      useClass: CommercialCategoryImplementationRepository,
    },
    {
      provide: UserRepository,
      useClass: UserImplRepository,
    },
    {
      provide: dukanShopRepositoryToken,
      useClass: DukanShopRepositoryImplementation,
    },
    {
      provide: merchantInsightsRepositoryToken,
      useClass: MerchantInsightsRepositoryImplementation,
    },
    { provide: LoyaltyProgramRepositoryToken, useClass: TierRepositoryImplementation },
    {
      provide: referralProgramRepositoryToken,
      useClass: ReferralProgramRepositoryImplementation,
    },
    {
      provide: DynamicIncentiveProgramRepositoryToken,
      useClass: DynamicIncentiveProgramRepositoryImplementation,
    },
    {
      provide: stockAvailabilityRepositoryToken,
      useClass: StockAvailabilityRepositoryImplementation,
    },
    {
      provide: CountriesRepository,
      useClass: CountryImplementationRepository,
    },
    {
      provide: ProductsRepository,
      useClass: ProductsImplementationRepository,
    },
    {
      provide: AnalyticsRepositoryInjectionToken,
      useClass: AnalyticsRepositoryImplementation,
    },
    {
      provide: UserLocationRepository,
      useClass: UserLocationRepositoryImplementation,
    },
    {
      provide: UserNotificationRepository,
      useClass: UserNotificationRepositoryImplementation,
    },
    {
      provide: BulkPreOrderRepository,
      useClass: BulkPreOrderImplementationRepository,
    },
    {
      provide: LearningRepository,
      useClass: LearningRepositoryImpl,
    },
    {
      provide: orderRepositoryInjectionToken,
      useClass: OrderRepositoryImpl,
    },
    {
      provide: cartRepositoryInjectionToken,
      useClass: CartRepositoryImplementation,
    },
    {
      provide: appUrlsConstantsInjectionToken,
      useValue: APP_URLS_CONSTANTS,
    },
    {
      provide: walletRepositoryInjectionToken,
      useClass: WalletRepositoryImplementation,
    },
    {
      provide: leadsRepositoryInjectionToken,
      useClass: LeadsRepositoryImplementation,
    },
    {
      provide: pricingCalculatorRepositoryToken,
      useClass: PricingCalculatorRepositoryImplementation,
    },
    {
      provide: userInjectionToken,
      useClass: UserImplRepository,
    },
    { provide: authInjectionToken, useClass: AuthRepositoryImplementation },
    {
      provide: ProvinceRepositoryToken,
      useClass: ProvinceRepositoryImplementation,
    },
    {
      provide: marketAvailabilityRepositoryToken,
      useClass: MarketAvailabilityRepositoryImplementation,
    },
    { provide: UtilitiesRepository, useClass: UtilitiesRepositoryImplementation },
  ],
})
export class DataModule {}
