import { AsyncPipe, NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { user } from '@features/user/data';
import { GetFeatureAttributeUsecase } from 'app/core/usecases/get-feature-attribute.usecase';
import { featureAttributeAssign } from 'app/presentation/shared/utilities/feature-attribute-assign.utility';
import { map, Observable } from 'rxjs';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import {
  MARKETPLACE_USER_FEATURE,
  WEB_MARKETPLACE_V2,
  WEB_STORES,
} from 'src/app/presentation/shared/constants/feature-flags';
import { SharedService } from 'src/app/presentation/shared/services/shared.service';
import { ThemeService } from 'src/app/presentation/shared/services/theme.service';
import { AccountDropdownComponent } from '../account-dropdown/account-dropdown.component';
import { ProductsSearchBarComponent } from '../products-search-bar/products-search-bar.component';
import { ShoppingNavigationComponent } from '../shopping-navigation/shopping-navigation.component';
import { StoresNavigationComponent } from '../stores-navigation/stores-navigation.component';
// eslint-disable-next-line prettier/prettier
import { country } from '@/v3/features/country/data/services/country.service';
// eslint-disable-next-line
import { NotificationsDropdownComponent } from '../notifications-dropdown/notifications-dropdown.component';

@Component({
  selector: 'app-sticky-header',
  templateUrl: './sticky-header.component.html',
  styleUrls: ['./sticky-header.component.scss'],
  standalone: true,
  imports: [
    RouterLink,
    ProductsSearchBarComponent,
    NgIf,
    StoresNavigationComponent,
    ShoppingNavigationComponent,
    NotificationsDropdownComponent,
    AccountDropdownComponent,
    AsyncPipe,
  ],
})
export class StickyHeaderComponent implements OnInit {
  public isLoggedIn = false;

  public logoRouteLink: string[];

  public isStoresEnabled = false;

  public isMarketPlaceV2Enabled = false;

  darkModeClass$: Observable<string>;

  darkMode$: Observable<boolean>;

  public user = user;

  constructor(
    private router: Router,
    private sharedService: SharedService,
    private _getFeatureFlagUsecase: GetFeatureFlagUsecase,
    private _themeService: ThemeService,
    private _getFeatureAttributeUseCase: GetFeatureAttributeUsecase,
    @Inject(appUrlsConstantsInjectionToken) private _appURLs: { [url: string]: string },
  ) {
    this.darkMode$ = this._themeService.isDarkMode();
    this.darkModeClass$ = this.darkMode$.pipe(
      map((isDarkMode) => (isDarkMode ? 'dark-mode' : 'light-mode')),
    );
  }

  ngOnInit(): void {
    this.setLogoRouteLink();
    this.router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationEnd) {
          this.setLogoRouteLink();
        }
      },
    });
    this.checkStores();
    this._enableMarketplaceV2();
  }

  private _enableMarketplaceV2(): void {
    this._getFeatureAttributeUseCase.execute(WEB_MARKETPLACE_V2).subscribe({
      next: (attribute) => {
        this.isMarketPlaceV2Enabled =
          featureAttributeAssign(attribute, user.id, country.code) &&
          user.features.includes(MARKETPLACE_USER_FEATURE);
      },
    });
  }

  checkStores(): void {
    this._getFeatureFlagUsecase.execute(WEB_STORES).subscribe({
      next: (flag) => {
        this.isStoresEnabled = flag;
      },
    });
  }

  setLogoRouteLink(): void {
    if (user.isLoggedIn) {
      this.logoRouteLink = [this._appURLs.PRODUCTS_V2_URL];
    } else {
      this.logoRouteLink = ['/'];
    }
  }

  onOpenMobileNavBar(): void {
    this.sharedService.emitOpenSideNavBar(true);
    this.router.navigate([{ outlets: { popup: null } }]);
  }
}
