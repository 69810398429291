/**
 * You may wonder why we are keeping these. Well, we will still keep these
 * because we want to have support for cases that are not in the angular
 * DI cycle.
 *
 * For example:
 *
 * 1. Other constants like header-tabs.ts
 *
 * And possibly in future, in routing modules, we may want to use app urls
 * as well for the path value.
 *
 * Primary usage will be in the likes of:
 *
 * 1. Components -> TS + HTML
 * 2. Guards
 */

export const WALLET_URL = '/wallet';
export const WALLET_AUTH_URL = '/wallet/auth';
export const STATISTICS_URL = '/statistics';
export const PRODUCTS_V2_URL = '/products';
export const CART_URL = '/cart';
export const CART_V2_URL = '/cart-v2';
export const CATALOG_URL = '/products/catalog';
export const ORDERS_URL = '/orders';
export const PRE_ORDER_REQUESTS_URL = '/pre-order-requests';
export const PROFILE_URL = '/profile';
export const COMPLAIN_SUGGEST_URL = '/complain-suggest';
export const SHIPPING_INFO_URL = '/info';
export const CATEGORY_PRODUCTS_URL = '/products/category';
export const LANDING_URL = '/';
export const AUTH_URL = '/auth';
export const QUESTIONNAIRE_URL = '/questionnaire';
export const USER_VERIFICATION_URL = '/user-verification';
export const ANDROID_APP_URL =
  'https://play.google.com/store/apps/details?id=com.taager.merchant&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1';
export const LOGIN_URL = '/auth/login';
export const REGISTER_URL = '/auth/signup';
export const RESET_PASSWORD_URL = '/reset-password';
export const TERMS_CONDITIONS_URL = '/terms-conditions';
export const INCENTIVE_URL = '/incentive-program';
export const PRODUCT_CHECKOUT_URL = '/product-checkout';
export const ONBOARDING_URL = '/questionnaire/onboarding';
export const ZID_INTEGRATION_URL = '/zid-integration';
export const YOUCAN_INTEGRATION_URL = '/youcan-integration';
export const WOOCOMMERCE_INTEGRATION_URL = '/woocommerce-integration';
export const RECOMMENDED_PRODUCTS_PAGE_URL = '/recommended';
export const ORDER_SUMMARY_ROUTE_URL = 'order-summary';
export const CATEGORY_PAGE_ROUTE = ['/products', 'category'];
export const ONBOARDING_LEARNING_COURSES_URL = 'onboarding/learning/course';
export const LEARNING_CENTER_URL = '/learning-center';
export const CREATIVES_URL = '/creatives';
export const STORES_URL = '/stores';
export const LOGIN_REDIRECT_TO_ANDROID =
  'https://play.google.com/store/apps/details?id=com.taager.merchant&referrer=utm_source%3Dtaager%26utm_medium%3Dlogin';
export const LOGIN_REDIRECT_TO_IOS =
  'https://apps.apple.com/app/apple-store/id6443435589?pt=125606533&ct=login&mt=8';
const EDIT_DUKAN_URL = 'edit-store';
const EDIT_DUKAN_PRODUCT_URL = 'edit-product';
export const MERCHANT_INSIGHTS_URL = '/insights';
export const CS_URL =
  'https://docs.google.com/forms/u/0/d/e/1FAIpQLScyURF4z0Z_m3j3ZSZUbWYP53c7RKm0dulbzamsbXreE1hZFw/viewform?pli=1';
export const PRODUCT_DETAILS = '/product-details';
export const LOYALTY_URL = '/loyalty';
export const REFERRAL_URL = '/referral';
export const KYC_URL = '/kyc';
/**
 * These are what will now be exported as a unit to be used as
 * value for DI.
 *
 * We can of-course use this as well in other constants where
 * we have hard-coding like in header-tabs, but it is not a must
 * because at the end of the day, the values come from section 1.
 *
 * HOWEVER, to reduce duplication i.e, adding a constant in 1 above and
 * then below, we can just decide to use this constant as the
 * single source of truth for the App URLs in the whole application.
 */
export const APP_URLS_CONSTANTS = {
  WALLET_URL,
  WALLET_AUTH_URL,
  STATISTICS_URL,
  PRODUCTS_V2_URL,
  CART_URL,
  CART_V2_URL,
  CATALOG_URL,
  KYC_URL,
  ORDERS_URL,
  PRE_ORDER_REQUESTS_URL,
  PROFILE_URL,
  COMPLAIN_SUGGEST_URL,
  SHIPPING_INFO_URL,
  CATEGORY_PRODUCTS_URL,
  LANDING_URL,
  AUTH_V2_URL: AUTH_URL,
  QUESTIONNAIRE_URL,
  USER_VERIFICATION_URL,
  ANDROID_APP_URL,
  LOGIN_V2_URL: LOGIN_URL,
  REGISTER_V2_URL: REGISTER_URL,
  RESET_PASSWORD_URL,
  TERMS_CONDITIONS_URL,
  INCENTIVE_URL,
  ONBOARDING_URL,
  ZID_INTEGRATION_URL,
  WOOCOMMERCE_INTEGRATION_URL,
  RECOMMENDED_PRODUCTS_PAGE_URL,
  ORDER_SUMMARY_ROUTE_URL,
  EDIT_DUKAN_URL,
  EDIT_DUKAN_PRODUCT_URL,
  ONBOARDING_LEARNING_COURSES_URL,
  LEARNING_CENTER_URL,
  CREATIVES_URL,
  STORES_URL,
  LOGIN_REDIRECT_TO_ANDROID,
  LOGIN_REDIRECT_TO_IOS,
  MERCHANT_INSIGHTS_URL,
  CS_URL,
  PRODUCT_DETAILS,
  REFERRAL_URL,
  LOYALTY_URL,
  preOrder: '/pre-order',
};
