import { NotificationReadModel } from 'app/core/domain/user-notification.model';
import { UserNotificationRepository } from 'app/core/repositories/user-notification.repository';
import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';

export class SetNotificationUseCase
  implements UseCase<{ notificationId: string; data: NotificationReadModel }, void>
{
  constructor(private _userNotificationRepository: UserNotificationRepository) {}

  execute(params: { notificationId: string; data: NotificationReadModel }): Observable<void> {
    return this._userNotificationRepository.readNotification(params);
  }
}
