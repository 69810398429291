/* eslint-disable @typescript-eslint/return-await */
import cache, { type CacheDriverInterface } from '@mongez/cache';
import { Endpoint, type RequestEndpointConfigurations } from '@mongez/http';
import { type GenericObject } from '@mongez/reinforcements';
import type { AxiosRequestConfig } from 'axios';
import { environment } from 'environment';
import type {
  EndpointOptions,
  HttpCacheOptions,
  HttpClientContract,
  HttpEventsContract,
  HttpRequestConfig,
  HttpResponse,
} from '../../domain/types';
import { HttpError } from './http-error';
import { HttpEvents } from './http-events';

export class HttpClient implements HttpClientContract {
  /**
   * Cache driver
   */
  protected cacheDriver: CacheDriverInterface = cache;

  /**
   * Http events
   */
  protected _events?: HttpEventsContract;

  /**
   * Constructor
   */
  public constructor() {
    this.prepareEvents();
  }

  /**
   * Prepare events
   */
  protected prepareEvents(): void {
    const prepareConfig = (options: AxiosRequestConfig<any>): HttpRequestConfig => {
      return {
        baseURL: options.baseURL!,
        path: options.url!,
        headers: (options.headers as GenericObject) || {},
        method: options.method as any,
        data: options.data,
        params: options.params,
        url: options.url!,
      };
    };

    this.driver.events.beforeSending((options) => {
      if (!this._events) return options;

      this._events.trigger('beforeSending', prepareConfig(options as any));
    });

    this.driver.events.onSuccess((response) => {
      if (!this._events) return response;

      this._events.trigger('onSuccess', {
        ...response,
        config: prepareConfig(response.config as any),
      });
    });

    this.driver.events.onError((response) => {
      if (!response) return;

      if (!this._events) return response;

      this._events.trigger('onError', {
        ...response,
        config: prepareConfig(response.config as any),
      });
    });

    this.driver.events.onComplete((response) => {
      if (!this._events) return response;

      if (!response) return;

      this._events.trigger('onComplete', {
        ...response,
        config: prepareConfig(response.config as any),
      });
    });
  }

  /**
   * Get http events manager
   */
  public get events(): HttpEventsContract {
    if (!this._events) {
      this._events = new HttpEvents();
    }

    return this._events!;
  }

  /**
   * Endpoint driver
   */
  protected driver = new Endpoint({
    cacheOptions: {
      driver: this.cacheDriver,
    },
  });

  /**
   * Default cache options
   */
  protected defaultCacheOptions: HttpCacheOptions = {
    ttl: Infinity,
    key: '',
  };

  public setCacheDriver(driver: CacheDriverInterface): HttpClientContract {
    this.cacheDriver = driver;

    return this;
  }

  /**
   * Set Cache Options
   */
  public setCacheOptions(options: HttpCacheOptions): HttpClientContract {
    this.defaultCacheOptions = options;

    if (options.ttl) {
      this.driver.setConfigurations({
        cacheOptions: {
          ttl: options.ttl,
          driver: this.cacheDriver,
        },
      });
    }

    return this;
  }

  public setBaseUrl(url: string): HttpClientContract {
    this.driver.setConfigurations({
      baseURL: url,
    });

    return this;
  }

  public async get<O>(path: string, options: EndpointOptions = {}): Promise<HttpResponse<O>> {
    return this.driver.get(path, this.prepareOptions(options));
  }

  public async post<I, O>(
    path: string,
    data: I,
    options?: EndpointOptions,
  ): Promise<HttpResponse<O>> {
    try {
      return await this.driver.post(path, data, this.prepareOptions(options));
    } catch (error) {
      if (error.response) {
        throw new HttpError(error.message, error.response);
      }

      throw error;
    }
  }

  public async put<I, O>(
    path: string,
    data: I,
    options?: EndpointOptions,
  ): Promise<HttpResponse<O>> {
    return this.driver.put(path, data, this.prepareOptions(options));
  }

  public async delete<O>(path: string, options?: EndpointOptions): Promise<HttpResponse<O>> {
    return this.driver.delete(path, this.prepareOptions(options));
  }

  public async patch<I, O>(path: string, data: I, options?: EndpointOptions): Promise<O> {
    return this.driver.patch(path, data, this.prepareOptions(options));
  }

  /**
   * Prepare options
   */
  protected prepareOptions(options?: EndpointOptions): RequestEndpointConfigurations | undefined {
    if (!options) return undefined;

    const finalOptions: RequestEndpointConfigurations = {
      ...options,
    } as RequestEndpointConfigurations;

    if (options.cache) {
      finalOptions.cache = true;
      finalOptions.cacheOptions = {
        ttl:
          options.cache !== true
            ? options.cache.ttl ?? this.defaultCacheOptions.ttl
            : this.defaultCacheOptions.ttl,
        driver: this.cacheDriver,
        key: options.cache !== true ? options.cache.key : undefined,
      };
    }

    return finalOptions;
  }
}

export const http = new HttpClient();

http.setBaseUrl(environment.BACKEND_URL_API);
