import { NgClass, NgForOf, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { BaseComponent } from 'app/presentation/base/base.component';
import { NotificationsDropdownItemComponent } from './notifications-dropdown-item/notifications-dropdown-item.component';
import {
  NotificationsDropdownPresenter,
  NotificationsDropdownViewState,
} from './notifications-dropdown.presenter';

@Component({
  selector: 'app-notifications-dropdown',
  templateUrl: './notifications-dropdown.component.html',
  styleUrls: ['./notifications-dropdown.component.scss'],
  standalone: true,
  imports: [
    MatMenuModule,
    MatButtonModule,
    NgClass,
    NgIf,
    NgForOf,
    NotificationsDropdownItemComponent,
  ],
  providers: [NotificationsDropdownPresenter],
})
export class NotificationsDropdownComponent extends BaseComponent<
  NotificationsDropdownPresenter,
  NotificationsDropdownViewState
> {
  public presenter: NotificationsDropdownPresenter = inject(NotificationsDropdownPresenter);

  protected onInit(): void {
    this.presenter.emitViewEvent({ type: 'Init' });
  }
}
