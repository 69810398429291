import { NotificationReadModel } from 'app/core/domain/user-notification.model';
import { UserNotificationRepository } from 'app/core/repositories/user-notification.repository';
import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';

export class SetUserNotificationsUseCase implements UseCase<NotificationReadModel, void> {
  constructor(private _userNotificationRepository: UserNotificationRepository) {}

  execute(params: NotificationReadModel): Observable<void> {
    return this._userNotificationRepository.readUserNotifications(params);
  }
}
